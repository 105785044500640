/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const NovoInfoForm = ({ funcoesSistema }) => {
  const {
    register,
    setValue,
    formState: { errors }
  } = useFormContext();

  const [listaFuncoesSistema, setListaFuncoesSistema] = useState([]);

  useEffect(() => {
    setListaFuncoesSistema(funcoesSistema);
    setValue('funcao_sistema', 0);
  }, [funcoesSistema]);

  return (
    <Card className="mb-1">
      <Card.Header as="h5" className="bg-body-tertiary">
        Informações Gerais
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="12">
            <Form.Group>
              <Form.Label>Descrição da função do sistema</Form.Label>
              <Form.Select
                name="funcao_sistema"
                isInvalid={!!errors.funcao_sistema}
                {...register('funcao_sistema')}
              >
                {listaFuncoesSistema != null &&
                listaFuncoesSistema.length > 0 ? (
                  <option value={0} key={0}>
                    {'Selecione a função do sistema'}
                  </option>
                ) : (
                  <option hidden value={-1} key={0}></option>
                )}

                {listaFuncoesSistema != null &&
                listaFuncoesSistema.length > 0 ? (
                  listaFuncoesSistema.map(item => (
                    <option value={item.id} key={item.id}>
                      {item.descricao} / {item.codigo}
                    </option>
                  ))
                ) : (
                  <option value={0} key={0} disabled>
                    {'Não existem funções do sistema disponíveis'}
                  </option>
                )}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.funcao_sistema?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default NovoInfoForm;

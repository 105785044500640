import axios from 'axios';

const http = axios.create({
  baseURL: 'https://rcitadm.com.br/api/api/v1/'
  //baseURL: 'http://localhost:8000/api/v1/'
});

http.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('token');
    //console.log('Token:' + token);
    if (token && config.headers) {
      config.headers.Authorization = `Token ${token}`;
    }
    return config;  
  },
  function (error) {
    console.log('Erro ao interceptar o axios');
    return Promise.reject(error);
  }
);

export default http;

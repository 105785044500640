/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import MaskedInput from 'react-text-mask';

const NovoFinanceiroForm = () => {
  const {
    register,
    formState: { errors }
  } = useFormContext();

  return (
    <Card className="mb-1">
      <Card.Header as="h5" className="bg-body-tertiary">
        Financeiro
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="6">
            <Form.Group>
              <Form.Label>Condição de pagamento</Form.Label>
              <Form.Control
                type="text"
                placeholder="Condição de pagamento do projeto"
                isInvalid={!!errors.condicao_pagamento}
                {...register('condicao_pagamento')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.condicao_pagamento?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="3">
            <Form.Group>
              <Form.Label>Valor da proposta</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">R$</InputGroup.Text>
                <Form.Control
                  type="number"
                  min={'0.00'}
                  max={'9999999.99'}
                  step={'0.01'}
                  placeholder="Valor da proposta do projeto"
                  isInvalid={!!errors.valor_proposta}
                  {...register('valor_proposta')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.valor_proposta?.message}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md="3">
            <Form.Group>
              <Form.Label>Valor do aceite</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">R$</InputGroup.Text>
                <Form.Control
                  type="number"
                  min={'0.00'}
                  max={'9999999.99'}
                  step={'0.01'}
                  placeholder="Valor do aceite do projeto"
                  isInvalid={!!errors.valor_aceite}
                  {...register('valor_aceite')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.valor_aceite?.message}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md="3">
            <Form.Group>
              <Form.Label>Quantidade de horas</Form.Label>
              <Form.Control
                type="number"
                min={'0.00'}
                max={'9999999.99'}
                step={'0.01'}
                placeholder="Quantidade de horas do projeto"
                isInvalid={!!errors.qtde_horas}
                {...register('qtde_horas')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.qtde_horas?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="3">
            <Form.Group>
              <Form.Label>Dia de faturamento</Form.Label>
              <Form.Control
                type="number"
                placeholder="Dia de faturamento do projeto"
                isInvalid={!!errors.dia_faturamento}
                {...register('dia_faturamento')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.dia_faturamento?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="3">
            <Form.Group>
              <Form.Label>Alíquota ISS</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  type="number"
                  min={'0.00'}
                  max={'9999999.99'}
                  step={'0.01'}
                  placeholder="Alíquota ISS do projeto"
                  isInvalid={!!errors.aliquota_iss}
                  {...register('aliquota_iss')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.aliquota_iss?.message}
                </Form.Control.Feedback>
                <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default NovoFinanceiroForm;

/* eslint-disable react/prop-types */
import coverSrc from 'assets/img/generic/4.jpg';
import avatar from 'assets/img/team/avatar.png';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import ProfileBanner from 'components/common/ProfileBanner';

const Banner = ({ usuario }) => {
  return (
    <ProfileBanner>
      <ProfileBanner.Header avatar={avatar} coverSrc={coverSrc} />
      <ProfileBanner.Body>
        <Row>
          <Col lg={8}>
            <h4 className="mb-1">{usuario?.nome_completo}</h4>
            <h5 className="fs-0 fw-normal">
              {usuario?.tipo_usuario.descricao}
            </h5>
            <div className="border-dashed border-bottom my-4 d-lg-none" />
          </Col>
        </Row>
      </ProfileBanner.Body>
    </ProfileBanner>
  );
};

export default Banner;

/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const InfoForm = ({ funcoesSistema, permissao }) => {
  const {
    register,
    setValue,
    formState: { errors }
  } = useFormContext();

  useEffect(() => {
    setValue('funcao_sistema', permissao?.funcao_sistema?.id);
  }, [funcoesSistema, permissao]);

  return (
    <Card className="mb-1">
      <Card.Header as="h5" className="bg-body-tertiary">
        Informações Gerais
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="12">
            <Form.Group>
              <Form.Label>Descrição da função do sistema</Form.Label>
              <Form.Select
                name="funcao_sistema"
                isInvalid={!!errors.funcao_sistema}
                {...register('funcao_sistema')}
              >
                <option value={0} key={0}>
                  {'Selecione a função do sistema'}
                </option>
                {funcoesSistema != null ? (
                  funcoesSistema.map(item => (
                    <option value={item.id} key={item.id}>
                      {item.descricao} / {item.codigo}
                    </option>
                  ))
                ) : (
                  <option value={0} key={0} disabled>
                    {'Não existem funções do sistema'}
                  </option>
                )}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.funcao_sistema?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default InfoForm;

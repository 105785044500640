/* eslint-disable react/prop-types */
import http from 'components/http';
import React, { useEffect, useState } from 'react';
import { Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';

const InfoForm = ({ consultores, registro }) => {
  const {
    register,
    setValue,
    formState: { errors }
  } = useFormContext();

  const [listaConsultores, setListaConsultores] = useState([]);
  const [cargos, setCargos] = useState([]);
  const [usuarioId, setUsuarioId] = useState('');
  const [valorHoraCargo, setValorHoraCargo] = useState('');

  const selecionaCargos = idUsuario => {
    setUsuarioId(idUsuario);
    http
      .get('consultores/cargos/usuarios/' + idUsuario)
      .then(resposta => {
        console.log(resposta.data);
        setCargos(resposta.data);
        setValue('cargo', 0);
      })
      .catch(erro => {
        if (erro.request.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          navigate('/auth/entrar');
        } else if (erro.request.status === 403) {
          toast.error('Você não possui permissão para aquela ação', {
            theme: 'colored',
            position: 'top-right'
          });

          navigate('/');
          //window.location.reload();
        } else {
          console.log(erro);
          toast.error('Erro ao listar as cargos', {
            theme: 'colored',
            position: 'top-right'
          });
        }
      });
  };

  const selecionaValorHora = (cargoId, param_id_usuario = null) => {
    let id_usuario = 0;
    if (param_id_usuario == null) id_usuario = usuarioId;
    else id_usuario = param_id_usuario;

    http
      .get(`consultores/cargos/${cargoId}/usuarios/${id_usuario}/atual`)
      .then(resposta => {
        setValorHoraCargo(resposta.data.valor_hora_cargo);
      })
      .catch(erro => {
        if (erro.request.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          navigate('/auth/entrar');
        } else if (erro.request.status === 403) {
          toast.error('Você não possui permissão para aquela ação', {
            theme: 'colored',
            position: 'top-right'
          });

          navigate('/');
          //window.location.reload();
        } else {
          console.log(erro);
          toast.error('Erro ao listar o valor hora do consultor', {
            theme: 'colored',
            position: 'top-right'
          });
        }
      });
  };

  useEffect(() => {
    if (registro?.usuario != '' && registro?.cargo != '') {
      setListaConsultores(consultores);
      selecionaCargos(registro?.usuario?.id);
      selecionaValorHora(registro?.cargo?.id, registro?.usuario?.id);

      setValue('projeto', registro?.projeto?.id);
      setValue('valor_hora_consultor', registro?.valor_hora_consultor);
      setValue('dt_inicio', registro?.dt_inicio);
      setValue('dt_fim', registro?.dt_fim);

      setTimeout(() => {
        setValue('usuario', registro?.usuario?.id);
        setValue('cargo', registro?.cargo?.id);
      }, 2000);
    }
  }, [registro, consultores]);

  return (
    <Card className="mb-1">
      <Card.Header as="h5" className="bg-body-tertiary">
        Informações Gerais
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="12">
            <Form.Group>
              <Form.Label>Consultor</Form.Label>
              <Form.Select
                name="usuario"
                isInvalid={!!errors.usuario}
                {...register('usuario')}
                onChange={e => {
                  setValue('usuario', e.target.value);
                  selecionaCargos(e.target.value);
                }}
              >
                {listaConsultores != null && listaConsultores.length > 0 ? (
                  <option value={0} key={0}>
                    {'Selecione um consultor'}
                  </option>
                ) : (
                  <option hidden value={-1} key={0}></option>
                )}

                {listaConsultores != null && listaConsultores.length > 0 ? (
                  listaConsultores.map(item => (
                    <option value={item.id} key={item.id}>
                      {item.cpf} / {item.nome_reduzido}
                    </option>
                  ))
                ) : (
                  <option value={0} key={0} disabled>
                    {'Não existem consultores cadastrados'}
                  </option>
                )}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.usuario?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <Form.Label>Cargo</Form.Label>
              <Form.Select
                name="cargo"
                {...register('cargo')}
                isInvalid={!!errors.cargo}
                onChange={e => {
                  setValue('cargo', e.target.value);
                  selecionaValorHora(e.target.value);
                }}
              >
                {cargos != null && cargos.length > 0 ? (
                  <option value={0} key={0}>
                    {'Selecione uma cargo'}
                  </option>
                ) : (
                  <option hidden value={-1} key={0}></option>
                )}

                {cargos != null && cargos.length > 0 ? (
                  cargos.map(item => (
                    <option value={item.cargo?.id} key={item.cargo?.id}>
                      {item.cargo?.descricao}
                    </option>
                  ))
                ) : (
                  <option value={0} key={0} disabled>
                    {'Selecione um consultor para visualizar suas cargos'}
                  </option>
                )}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.cargo?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <Form.Label>Valor hora no projeto</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">R$</InputGroup.Text>
                <Form.Control
                  type="number"
                  min={'0.00'}
                  max={'999.00'}
                  step={'0.01'}
                  placeholder="Valor hora do consultor no projeto"
                  isInvalid={!!errors.valor_hora_consultor}
                  onChange={e => {
                    setValue('valor_hora_consultor', e.target.value);
                  }}
                  {...register('valor_hora_consultor')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.valor_hora_consultor?.message}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <Form.Label>Valor hora padrão</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Text id="basic-addon1">R$</InputGroup.Text>
                <Form.Control
                  disabled
                  type="number"
                  value={valorHoraCargo}
                  placeholder="Valor hora padrão do consultor"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.valor_hora_consultor?.message}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <Form.Label>Data inicial</Form.Label>
              <Form.Control
                type="date"
                placeholder="Data inicial do período"
                isInvalid={!!errors.dt_inicio}
                onChange={e => {
                  setValue('dt_inicio', e.target.value);
                }}
                {...register('dt_inicio')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.dt_inicio?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <Form.Label>Data final (opcional)</Form.Label>
              <Form.Control
                type="date"
                placeholder="Data final do período"
                isInvalid={!!errors.dt_fim}
                onChange={e => {
                  setValue('dt_fim', e.target.value);
                }}
                {...register('dt_fim')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.dt_fim?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default InfoForm;

import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Card, Button } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import NovoInfoForm from './NovoInfoForm';
import { useNavigate } from 'react-router-dom';
import http from 'components/http';
import { toast } from 'react-toastify';
import NovoContatoForm from './NovoContatoForm';
import NovoEnderecoForm from './NovoEnderecoForm';

const schema = yup
  .object({
    cliente_agrupador: yup.string().optional().nullable(),
    cnpj: yup
      .string()
      .required('Esse campo é obrigatório')
      .length(18, 'CNPJ inválido'),
    razao_social: yup.string().required('Esse campo é obrigatório'),
    nome_fantasia: yup.string().required('Esse campo é obrigatório'),
    centro_custos: yup.string().optional().nullable(),
    complemento: yup.string(),
    cep: yup.string().min(10, 'Esse campo deve ter no mínimo 10 caracteres'),
    bairro: yup.string().required('Esse campo é obrigatório'),
    cidade: yup.string().required('Esse campo é obrigatório'),
    estado: yup
      .string()
      .required('Esse campo é obrigatório')
      .length(2, 'Estado não existe'),
    pais: yup.string().required('Esse campo é obrigatório'),
    nome_contato_1: yup.string().required('Esse campo é obrigatório'),
    email_contato_1: yup
      .string()
      .email('Email inválido')
      .required('Esse campo é obrigatório'),
    celular_contato_1: yup
      .string()
      .required('Esse campo é obrigatório')
      .min(8, 'Esse campo deve ter no mínimo 8 caracteres'),
    fone_contato_1: yup
      .string()
      .required('Esse campo é obrigatório')
      .min(8, 'Esse campo deve ter no mínimo 8 caracteres'),
    setor_contato_1: yup.string().required('Esse campo é obrigatório'),
    nome_contato_2: yup.string().optional().nullable(),
    email_contato_2: yup.string().email('Email inválido').optional().nullable(),
    celular_contato_2: yup.string().optional().nullable(),
    fone_contato_2: yup.string().optional().nullable(),
    setor_contato_2: yup.string().optional().nullable(),
    nome_contato_3: yup.string().optional().nullable(),
    email_contato_3: yup.string().email('Email inválido').optional().nullable(),
    celular_contato_3: yup.string().optional().nullable(),
    fone_contato_3: yup.string().optional().nullable(),
    setor_contato_3: yup.string().optional().nullable()
  })
  .required();

const NovoClienteForm = () => {
  const navigate = useNavigate();

  const [clientes, setClientes] = useState([]);
  const [cepVazio, setCepVazio] = useState(null);
  const [cnpjVazio, setCnpjVazio] = useState(null);
  const [logomarca, setLogomarca] = useState('');

  const insereImagem = img => {
    setLogomarca(img);
  };

  useEffect(() => {
    http
      .get('clientes')
      .then(resposta => {
        setClientes(resposta.data);
      })
      .catch(erro => {
        if (erro.request.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          navigate('/auth/entrar');
        } else if (erro.request.status === 403) {
          toast.error('Você não possui permissão para aquela ação', {
            theme: 'colored',
            position: 'top-right'
          });

          navigate('/');
          //window.location.reload();
        } else {
          toast.error('Erro ao listar os clientes', {
            theme: 'colored',
            position: 'top-right'
          });
          console.log(erro);
        }
      });
  }, []);

  const submittedValues = {};

  const methods = useForm({
    resolver: yupResolver(schema)
  });

  const { handleSubmit, reset, setError, setValue } = methods;

  const onSubmit = data => {
    if (data['cliente_agrupador'] == 0) data['cliente_agrupador'] = '';

    data['logomarca'] = logomarca;
    console.log(data);
    http
      .request({
        url: 'clientes',
        method: 'POST',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: data
      })
      .then(() => {
        const keys = Object.keys(data);
        for (const key of keys) {
          submittedValues[key] = '';
        }
        reset({ ...submittedValues });

        setValue('cliente_agrupador', 0);
        setCepVazio('');
        setCnpjVazio('');
        insereImagem('');

        toast.success('Cliente cadastrado com sucesso!', {
          theme: 'colored',
          position: 'top-right'
        });
      })
      .catch(erro => {
        console.log(erro);
        if (erro.request.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          navigate('/auth/entrar');
        } else if (erro.request.status === 403) {
          toast.error('Você não possui permissão para essa ação', {
            theme: 'colored',
            position: 'top-right'
          });
        } else {
          toast.error('Erro ao cadastrar o cliente!', {
            theme: 'colored',
            position: 'top-right'
          });

          for (let key in erro.response.data) {
            console.log(erro.response.data[key]);
            setError(key, {
              type: 'custom',
              message: erro.response.data[key]
            });
          }

          if (Object.keys(erro.response.data).includes('logomarca')) {
            toast.error(
              'Campo logomarca: ' + erro.response.data['logomarca'][0],
              {
                theme: 'colored',
                position: 'top-right'
              }
            );
          }
        }
      });
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="g-3">
          <Col xs={12}>
            <Card>
              <Card.Body>
                <Row className="flex-between-center">
                  <Col md>
                    <h5 className="mb-2 mb-md-0">Novo Cliente</h5>
                  </Col>
                  <Col xs="auto">
                    <Button
                      variant="link"
                      className="text-secondary fw-medium p-0 me-3"
                      type="button"
                      onClick={() => navigate(-1)}
                    >
                      Voltar
                    </Button>
                    <Button variant="primary" type="submit">
                      Adicionar
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={12}>
            <NovoInfoForm
              clientes={clientes}
              cnpjVazio={cnpjVazio}
              insereImagem={insereImagem}
              logomarca={logomarca}
            />
          </Col>
          <Col lg={12}>
            <NovoEnderecoForm cepVazio={cepVazio} />
          </Col>
          <Col lg={12}>
            <NovoContatoForm />
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};

export default NovoClienteForm;

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const RedirecionamentoHome = () => {
  const navigate = useNavigate();

  useEffect(() => {
    let usuario = JSON.parse(localStorage.getItem('user'));

    if (usuario?.tipo_usuario?.descricao == 'Administrador')
      navigate('/dashboard');
    else navigate('/apontamentos/usuarios/' + usuario?.id);
  }, []);
};

export default RedirecionamentoHome;

/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import http from 'components/http';

const ModalExcluirTarefa = ({ show, onHide, tarefa }) => {
  const navigate = useNavigate();

  const excluirTarefa = () => {
    http
      .request({
        url: 'tarefas/' + tarefa.id,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(() => {
        toast.success('Tarefa excluída com sucesso!', {
          theme: 'colored',
          position: 'top-right'
        });

        navigate('/tarefas');
      })
      .catch(erro => {
        console.log(erro);
        if (erro.request.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          navigate('/auth/entrar');
        } else if (erro.request.status === 403) {
          toast.error('Você não possui permissão para essa ação', {
            theme: 'colored',
            position: 'top-right'
          });

          onHide();
        } else {
          toast.error('Erro ao excluir a tarefa!', {
            theme: 'colored',
            position: 'top-right'
          });

          onHide();
        }
      });
  };

  return (
    <Modal show={show} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Body>
        <h5>Deseja realmente excluir essa tarefa?</h5>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={excluirTarefa}>Sim</Button>
        <Button onClick={onHide} variant="outline-primary">
          Não
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalExcluirTarefa;

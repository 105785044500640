import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import http from 'components/http';
import { toast } from 'react-toastify';
import IconButton from 'components/common/IconButton';

const Permissoes = () => {
  const [permissoes, setPermissoes] = useState([]);
  const [usuario, setUsuario] = useState('');
  const navigate = useNavigate();
  const { usuarioId } = useParams();

  useEffect(() => {
    http
      .get(`usuarios/${usuarioId}/perfis`)
      .then(resposta => {
        setPermissoes(resposta.data);
        setUsuario(resposta.data[0]?.usuario);
      })
      .catch(erro => {
        if (erro.request.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          navigate('/auth/entrar');
        } else if (erro.request.status === 403) {
          toast.error('Você não possui permissão para aquela ação', {
            theme: 'colored',
            position: 'top-right'
          });

          navigate('/');
          //window.location.reload();
        } else {
          console.log(erro);
          toast.error('Erro ao listar as permissões do usuário', {
            theme: 'colored',
            position: 'top-right'
          });
        }
      });
  }, []);

  const columns = [
    {
      accessor: '#',
      Header: '#',
      Cell: rowData => {
        const { id } = rowData.row.original;
        const cont = rowData.row.index;
        return (
          <a
            href={'#'}
            onClick={e => {
              e.preventDefault();
              navigate(`/usuarios/${usuarioId}/permissoes/${id}`);
            }}
          >
            {cont + 1}
          </a>
        );
      }
    },
    {
      accessor: 'funcao_sistema',
      Header: 'Função do sistema permitida',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { id, funcao_sistema } = rowData.row.original;
        return (
          <a
            href={'#'}
            onClick={e => {
              e.preventDefault();
              navigate(`/usuarios/${usuarioId}/permissoes/${id}`);
            }}
          >
            {funcao_sistema?.descricao}
          </a>
        );
      }
    }
  ];
  return (
    <AdvanceTableWrapper
      columns={columns}
      data={permissoes}
      sortable
      pagination
      perPage={10}
    >
      <Card className="mb-3">
        <Card.Header>
          <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
              <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                Permissões de {usuario?.nome_reduzido}
              </h5>
            </Col>
            <Col sm="auto" className="ms-auto text-end ps-0">
              <div
                id="orders-actions"
                className="d-flex align-items-center mb-1"
              >
                <IconButton
                  variant="falcon-default"
                  size="sm"
                  icon="plus"
                  transform="shrink-3"
                  className="me-3"
                  style={{ width: 110 }}
                  onClick={e => {
                    e.preventDefault();
                    navigate(`/usuarios/${usuarioId}/permissoes/adicionar`);
                  }}
                >
                  <span className="d-none d-sm-inline-block ms-1">Nova</span>
                </IconButton>
                <AdvanceTableSearchBox table placeholder={'Procurar'} />
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'sm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTableFooter
            rowCount={permissoes.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
          />
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

export default Permissoes;

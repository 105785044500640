import React, { Fragment, useEffect } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const GereciamentoForm = ({ projeto }) => {
  const {
    register,
    setValue,
    formState: { errors }
  } = useFormContext();

  useEffect(() => {
    setValue('nome_responsavel_adm', projeto.nome_responsavel_adm);
    setValue('nome_gerente_projeto_rcit', projeto.nome_gerente_projeto_rcit);
    setValue(
      'nome_gerente_projeto_cliente',
      projeto.nome_gerente_projeto_cliente
    );
    setValue(
      'nome_diretor_projeto_cliente',
      projeto.nome_diretor_projeto_cliente
    );
  }, [projeto]);

  return (
    <Fragment>
      <Card className="mb-3">
        <Card.Header as="h5" className="bg-body-tertiary">
          Gerenciamento
        </Card.Header>
        <Card.Body>
          <Row className="gx-2 gy-3">
            <Col md="6">
              <Form.Group>
                <Form.Label>Nome do responsável - Administração</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nome do responsável - Administração"
                  isInvalid={!!errors.nome_responsavel_adm}
                  onChange={e =>
                    setValue('nome_responsavel_adm', e.target.value)
                  }
                  {...register('nome_responsavel_adm')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.nome_responsavel_adm?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                <Form.Label>Nome do gerente do projeto - RCiT</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nome do gerente do projeto - RCiT"
                  isInvalid={!!errors.nome_gerente_projeto_rcit}
                  onChange={e =>
                    setValue('nome_gerente_projeto_rcit', e.target.value)
                  }
                  {...register('nome_gerente_projeto_rcit')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.nome_gerente_projeto_rcit?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                <Form.Label>Nome do gerente do projeto - Cliente</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nome do gerente do projeto - Cliente"
                  isInvalid={!!errors.nome_gerente_projeto_cliente}
                  onChange={e =>
                    setValue('nome_gerente_projeto_cliente', e.target.value)
                  }
                  {...register('nome_gerente_projeto_cliente')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.nome_gerente_projeto_cliente?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                <Form.Label>Nome do diretor do projeto - Cliente</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nome do diretor do projeto - Cliente"
                  isInvalid={!!errors.nome_diretor_projeto_cliente}
                  onChange={e =>
                    setValue('nome_diretor_projeto_cliente', e.target.value)
                  }
                  {...register('nome_diretor_projeto_cliente')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.nome_diretor_projeto_cliente?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default GereciamentoForm;

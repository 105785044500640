import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import IconButton from 'components/common/IconButton';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import http from 'components/http';
import { toast } from 'react-toastify';

const Apontamentos = () => {
  const [apontamentos, setApontamentos] = useState([]);
  const [apontamentosFiltrados, setApontamentosFiltrados] = useState([]);
  const [usuario, setUsuario] = useState('');
  const [mesComZero, setMesComZero] = useState('');
  const [diasTrabalhados, setDiasTrabalhados] = useState(0);
  const [cargaHorariaRelativa, setCargaHorariaRelativa] = useState(0.0);
  const [cargaHorariaNominal, setCargaHorariaNominal] = useState('00:00');
  //const [usuario, setUsuario] = useState('');
  const { usuarioId, mes, ano } = useParams();
  const navigate = useNavigate();
  const mesesUltimoDia = {
    1: '31',
    2: '29',
    3: '31',
    4: '30',
    5: '31',
    6: '30',
    7: '31',
    8: '31',
    9: '30',
    10: '31',
    11: '30',
    12: '31'
  };

  const diasNome = {
    0: 'Seg',
    1: 'Ter',
    2: 'Qua',
    3: 'Qui',
    4: 'Sex',
    5: 'Sáb',
    6: 'Dom'    
  };

  const mesesNome = {
    1: 'Janeiro',
    2: 'Fevereiro',
    3: 'Março',
    4: 'Abril',
    5: 'Maio',
    6: 'Junho',
    7: 'Julho',
    8: 'Agosto',
    9: 'Setembro',
    10: 'Outubro',
    11: 'Novembro',
    12: 'Dezembro'
  };

  useEffect(() => {
    let listaApontamentos = [];

    http
      .get(`usuarios/` + usuarioId)
      .then(resposta => {
        setUsuario(resposta.data);
      })
      .catch(erro => {
        if (erro.request.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          navigate('/auth/entrar');
        } else if (erro.request.status === 403) {
          toast.error('Você não possui permissão para aquela ação', {
            theme: 'colored',
            position: 'top-right'
          });

          navigate('/');
          //window.location.reload();
        } else {
          console.log(erro);
          toast.error('Erro ao listar os apontamentos', {
            theme: 'colored',
            position: 'top-right'
          });
        }
      });

    http
      .get(`apontamentos/${mes}/${ano}/usuarios/${usuarioId}`)
      .then(resposta => {
        setApontamentos(resposta.data);
        setApontamentosFiltrados(resposta.data);
        listaApontamentos = resposta.data;
        let diasUnicos = new Set();
        let somaCargaHorariaRelativa = 0.0;
        listaApontamentos.forEach(item => {
          diasUnicos.add(item.data_atividade.split('-')[2]);

          somaCargaHorariaRelativa += parseFloat(
            item.qtde_horas_tarefa_relativa
          );
        });
        //console.log(diasUnicos);
        let quantidadeDiasTrabalhados = diasUnicos.size;

        if (quantidadeDiasTrabalhados < 10)
          quantidadeDiasTrabalhados = '0' + quantidadeDiasTrabalhados;

        setDiasTrabalhados(quantidadeDiasTrabalhados);

        let somaHoras = parseFloat(somaCargaHorariaRelativa)
          .toFixed(2)
          .toString()
          .split('.');

        let minutos = parseInt(parseFloat('0.' + somaHoras[1]) * 60);
        let horas = parseInt(somaHoras[0]);

        if (horas < 10) horas = '0' + horas;

        if (minutos < 10) minutos = '0' + minutos;

        setCargaHorariaRelativa(horas + '.' + somaHoras[1]);
        setCargaHorariaNominal(horas + ':' + minutos);
      })
      .catch(erro => {
        if (erro.request.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          navigate('/auth/entrar');
        } else if (erro.request.status === 403) {
          toast.error('Você não possui permissão para aquela ação', {
            theme: 'colored',
            position: 'top-right'
          });

          navigate('/');
          //window.location.reload();
        } else {
          console.log(erro);
          toast.error('Erro ao listar os apontamentos', {
            theme: 'colored',
            position: 'top-right'
          });
        }
      });

    if (parseInt(mes) < 10) setMesComZero('0' + mes);
    else setMesComZero(mes);
  }, []);

  const downloadExcel = () => {    
    toast.info('Aguarde alguns instantes enquanto o arquivo é gerado', {
      theme: 'colored',
      position: 'top-right'
    });
    
    http
        .get(`apontamentos/${mes}/${ano}/usuarios/${usuarioId}/excel`)
        .then(() => {            
            //Build a URL from the file           
            //Open the URL on new Window                
            const tempLink = document.createElement("a");
            tempLink.href = `https://rcitadm.com.br/media/apontamentos_${usuarioId}.xlsx`;
            tempLink.setAttribute(
                "download",
                "apontamentos.xlsx"
            ); // Set the desired filename for the downloaded file

            // Append the <a> element to the body and click it to trigger the download
            document.body.appendChild(tempLink);
            tempLink.click();

            // Clean up the temporary elements and URL
            document.body.removeChild(tempLink);            
        })
        .catch(erro => {
          console.log(erro)  
          if (erro.request.status === 401) {
                localStorage.removeItem('token');
                localStorage.removeItem('user');

                navigate('/auth/entrar');
            } else if (erro.request.status === 403) {
                toast.error('Você não possui permissão para aquela ação', {
                    theme: 'colored',
                    position: 'top-right'
                });

                navigate('/');
                //window.location.reload();
            } else {
                //console.log(erro);
                toast.error('Erro ao gerar arquivo!', {
                    theme: 'colored',
                    position: 'top-right'
                });
            }
        });
}

  const filtraApontamentosPorDia = (porDiaAtual = 'N') => {
    if (porDiaAtual == 'S') {
      let diaAtual = new Date().getDate();      
      let listaApontamentos = apontamentos.filter(apontamento => {
        let dia_atividade = apontamento.data_atividade.split('-')[2];
        if (dia_atividade == diaAtual) return apontamento;
      });

      setApontamentosFiltrados(listaApontamentos);
    } else {
      setApontamentosFiltrados(apontamentos);
    }
  };

  const columns = [
    {
      accessor: 'dia',
      Header: 'Dia Mês',
      headerProps: { className: 'text-900' }, 
      cellProps: { className: 'text-center' },     
      Cell: rowData => {
        const { id, data_atividade } = rowData.row.original;
        const data = new Date(data_atividade)
        const nomeDia = diasNome[data.getDay()]
        return (
          <a
            href={'#'}
            onClick={e => {
              e.preventDefault();
              navigate(`/apontamentos/${id}`);
            }}
          >
            {data_atividade?.split('-')[2]} - {nomeDia}
          </a>
        );
      }
    },
    {
      accessor: 'cargo',
      Header: 'Cargo',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { id, cargo } = rowData.row.original;
        return (
          <a
            href={'#'}
            onClick={e => {
              e.preventDefault();
              navigate(`/apontamentos/${id}`);
            }}
          >
            {cargo?.descricao}
          </a>
        );
      }
    },
    {
      accessor: 'projeto',
      Header: 'Projeto',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { id, projeto } = rowData.row.original;
        return (
          <a
            href={'#'}
            onClick={e => {
              e.preventDefault();
              navigate(`/apontamentos/${id}`);
            }}
          >
            {projeto?.nome}
          </a>
        );
      }
    },    
    {
      accessor: 'hora_inicio',
      Header: 'Hora Início',
      headerProps: { className: 'text-900' },
      cellProps: { className: 'text-center' },
      Cell: rowData => {
        const { id, hora_inicio } = rowData.row.original;

        return (
          <a
            href={'#'}
            onClick={e => {
              e.preventDefault();
              navigate(`/apontamentos/${id}`);
            }}
          >
            {hora_inicio}
          </a>
        );
      }
    },
    {
      accessor: 'hora_fim',
      Header: 'Hora Fim',
      headerProps: { className: 'text-900' },
      cellProps: { className: 'text-center' },
      Cell: rowData => {
        const { id, hora_fim } = rowData.row.original;

        return (
          <a
            href={'#'}
            onClick={e => {
              e.preventDefault();
              navigate(`/apontamentos/${id}`);
            }}
          >
            {hora_fim}
          </a>
        );
      }
    },
    {
      accessor: 'qtde_horas_tarefa_nominal',
      Header: 'Hora Nominal',
      headerProps: { className: 'text-900' },
      cellProps: { className: 'text-center' },
      Cell: rowData => {
        const { id, qtde_horas_tarefa_nominal } = rowData.row.original;

        return (
          <a
            href={'#'}
            onClick={e => {
              e.preventDefault();
              navigate(`/apontamentos/${id}`);
            }}
          >
            {qtde_horas_tarefa_nominal}
          </a>
        );
      }
    },
    {
      accessor: 'qtde_horas_tarefa_relativa',
      Header: 'Hora Relativa',
      headerProps: { className: 'text-900' },
      cellProps: { className: 'text-center' },
      Cell: rowData => {
        const { id, qtde_horas_tarefa_relativa } = rowData.row.original;

        return (
          <a
            href={'#'}
            onClick={e => {
              e.preventDefault();
              navigate(`/apontamentos/${id}`);
            }}
          >
            {qtde_horas_tarefa_relativa}
          </a>
        );
      }
    },
    {      
      accessor: 'tarefa',
      Header: 'Tarefa',
      headerProps: { className: 'text-900' },      
      Cell: rowData => {
        const { id, tarefa } = rowData.row.original;

        return (
          <a
            href={'#'}
            onClick={e => {
              e.preventDefault();
              navigate(`/apontamentos/${id}`);
            }}
          >
            {tarefa.descricao}
          </a>
        );
      }
    },
    {
      accessor: 'desc_atividade',
      Header: 'Atividade',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { id, desc_atividade } = rowData.row.original;

        return (
          <a
            href={'#'}
            onClick={e => {
              e.preventDefault();
              navigate(`/apontamentos/${id}`);
            }}
          >
            {desc_atividade}
          </a>
        );
      }
    }
  ];

  return (
    <AdvanceTableWrapper
      columns={columns}
      data={apontamentosFiltrados}
      sortable
      pagination
      perPage={60}
    >
      <Card className="mb-3">
        <Card.Header>
          <Row className="flex-between-center">
            <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
              <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                Apontamentos - {mesesNome[mes]}/{ano}
              </h5>
            </Col>
            <Col sm="auto" className="ms-auto text-end ps-0">
              <div
                id="orders-actions"
                className="d-flex align-items-center mb-1"
              >
                <IconButton
                  variant="falcon-default"
                  size="sm"
                  icon="plus"
                  transform="shrink-3"
                  className="me-3"
                  style={{ width: 110 }}
                  onClick={e => {
                    e.preventDefault();
                    navigate(
                      '/apontamentos/usuarios/' +
                        usuarioId +
                        '/' +
                        ano +
                        '/' +
                        mes +
                        '/adicionar'
                    );
                  }}
                >
                  <span className="d-none d-sm-inline-block ms-1">Novo</span>
                </IconButton>
                <AdvanceTableSearchBox table placeholder={'Procurar'} />
              </div>
            </Col>
          </Row>

          <br />
          <Row>
            <Col xs={6}>
              <h6>Consultor: ..... {usuario?.nome_reduzido}</h6>
              <h6>Email: .............. {usuario?.email}</h6>
              <h6>
                Período: ......... 01/{mesComZero}/{ano} à {mesesUltimoDia[mes]}
                /{mesComZero}/{ano}
              </h6>
            </Col>
          </Row>
          <Row className="justify-content-end">                                    
            <Col xs={3} className="col align-self-end">
              <Form.Select
                  size="sm"
                  className="shadow-none"
                  name="filtro"
                  onChange={e => filtraApontamentosPorDia(e.target.value)}
                >
                  <option value={'N'} key={0} disabled selected>
                    {'Filtrar por'}
                  </option>
                  <option value={'N'} key={1}>
                    {'Todos os dias'}
                  </option>
                  <option value={'S'} key={2}>
                    {'Dia atual'}
                  </option>
              </Form.Select>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0">
          <AdvanceTable
            table
            headerClassName="bg-200 text-nowrap align-middle"
            rowClassName="align-middle white-space-nowrap"
            tableProps={{
              size: 'sm',
              striped: true,
              className: 'fs--1 mb-0 overflow-hidden'
            }}
          />
        </Card.Body>
        <Card.Footer>
          <AdvanceTableFooter
            rowCount={apontamentosFiltrados.length}
            table
            rowInfo
            navButtons
            rowsPerPageSelection
          />
          <br />
          <br />
          <Row>
            <Col xs={6}>
              <h6>Dias Trabalhados: ................... {diasTrabalhados}</h6>
              <h6>Carga Horária Relativa: ........ {cargaHorariaRelativa}</h6>
              <h6>Carga Horária Nominal: ....... {cargaHorariaNominal}</h6>
            </Col>
            <Col sm="auto" className="ms-auto text-end ps-0 align-self-end">
              <div
                id="orders-actions"
                className="d-flex align-items-end mb-1"
              >                
                <IconButton
                  variant="falcon-default"
                  size="sm"
                  icon="download"
                  transform="shrink-3"
                  className="me-3"
                  //style={{ width: 110 }}                  
                  onClick={e => {
                    e.preventDefault();
                    downloadExcel();
                  }}
                >
                  <span className="d-none d-sm-inline-block ms-1">Gerar planilha</span>
                </IconButton>                
                {/* <IconButton
                  variant="falcon-default"
                  size="sm"
                  icon="download"
                  transform="shrink-3"
                  className="me-3"
                  //style={{ width: 110 }}                  
                  onClick={e => {
                    e.preventDefault();
                    print()
                  }}
                >
                  <span className="d-none d-sm-inline-block ms-1">PDF</span>
                </IconButton>                 */}
              </div>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </AdvanceTableWrapper>
  );
};

export default Apontamentos;

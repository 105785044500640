import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const NovoContatoForm = () => {
  const {
    register,
    formState: { errors }
  } = useFormContext();

  return (
    <Card className="mb-1">
      <Card.Header as="h5" className="bg-body-tertiary">
        Contato
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="7">
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Email do usuário"
                isInvalid={!!errors.email}
                {...register('email')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="5">
            <Form.Group>
              <Form.Label>Celular</Form.Label>
              <Form.Control
                type="text"
                placeholder="(85) 9.9999-9999"
                isInvalid={!!errors.celular}
                {...register('celular')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.celular?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <Form.Label>Fone 1 (opcional)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Outra opção de celular do usuário"
                isInvalid={!!errors.fone_1}
                {...register('fone_1')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.fone_1?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <Form.Label>Fone 2 (opcional)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Outra opção de celular do usuário"
                isInvalid={!!errors.fone_2}
                {...register('fone_2')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.fone_2?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <Form.Label>Fone 3 (opcional)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Outra opção de celular do usuário"
                isInvalid={!!errors.fone_3}
                {...register('fone_3')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.fone_3?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default NovoContatoForm;

import http from 'components/http';
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Form, Row, Card, Button } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import InfoForm from './InfoForm';
import LogForm from './LogForm';
import ModalExcluirPermissao from './ModalExcluirPermissao';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const schema = yup
  .object({
    funcao_sistema: yup
      .number()
      .moreThan(0, 'Esse campo é obrigatório')
      .required('Esse campo é obrigatório')
  })
  .required();

const PermissaoForm = () => {
  const navigate = useNavigate();

  const { usuarioId, id } = useParams();

  const [funcoesSistema, setFuncoesSistema] = useState([]);
  const [permissao, setPermissao] = useState({
    id: '',
    usuario: '',
    funcao_sistema: '',
    usuario_insert: '',
    usuario_update: '',
    data_hora_insert: '',
    data_hora_update: ''
  });

  const [showModalExcluir, setShowModalExcluir] = useState(false);

  useEffect(() => {
    let funcaoSistemaId = 0;

    http
      .get('usuarios/perfis/' + id)
      .then(resposta => {
        funcaoSistemaId = resposta.data.funcao_sistema.id;
        setPermissao(resposta.data);
      })
      .catch(erro => {
        console.log(erro);

        if (erro.request.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          navigate('/auth/entrar');
        } else if (erro.request.status === 403) {
          toast.error('Você não possui permissão para aquela ação', {
            theme: 'colored',
            position: 'top-right'
          });

          navigate('/');
        } else {
          toast.error('Erro ao exibir a permissão', {
            theme: 'colored',
            position: 'top-right'
          });

          navigate(`/usuarios/${usuarioId}/permissoes`);
        }
      });

    http
      .get(`usuarios/${usuarioId}/perfis`)
      .then(resposta => {
        let listaPermissoesUsuario = resposta.data;

        http
          .get('sistema/funcoes')
          .then(respostaFuncoes => {
            let novasFuncoes = respostaFuncoes.data.filter(funcao => {
              let isNovaFuncao = true;
              listaPermissoesUsuario.map(perfil => {
                if (
                  funcao.id === perfil.funcao_sistema.id &&
                  perfil.funcao_sistema.id !== funcaoSistemaId
                )
                  isNovaFuncao = false;
              });

              if (isNovaFuncao === true) {
                return funcao;
              }
            });
            setFuncoesSistema(novasFuncoes);
          })
          .catch(erro => {
            if (erro.request.status === 401) {
              localStorage.removeItem('token');
              localStorage.removeItem('user');

              navigate('/auth/entrar');
            } else if (erro.request.status === 403) {
              toast.error('Você não possui permissão para aquela ação', {
                theme: 'colored',
                position: 'top-right'
              });

              navigate('/');
              //window.location.reload();
            } else {
              toast.error('Erro ao listar as funções do sistema', {
                theme: 'colored',
                position: 'top-right'
              });
              console.log(erro);
            }
          });
      })
      .catch(erro => {
        if (erro.request.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          navigate('/auth/entrar');
        } else if (erro.request.status === 403) {
          toast.error('Você não possui permissão para aquela ação', {
            theme: 'colored',
            position: 'top-right'
          });

          navigate('/');
          //window.location.reload();
        } else {
          console.log(erro);
          toast.error('Erro ao listar as permissões do usuário', {
            theme: 'colored',
            position: 'top-right'
          });
        }
      });
  }, []);

  const methods = useForm({
    resolver: yupResolver(schema)
  });

  const { handleSubmit, setError } = methods;

  const onSubmit = data => {
    data['usuario'] = usuarioId;
    http
      .request({
        url: 'usuarios/perfis/' + permissao.id,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      })
      .then(response => {
        setPermissao(response.data);

        toast.success('Permissão editada com sucesso!', {
          theme: 'colored',
          position: 'top-right'
        });
      })
      .catch(erro => {
        console.log(erro);
        if (erro.request.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          navigate('/auth/entrar');
        } else if (erro.request.status === 403) {
          toast.error('Você não possui permissão para essa ação', {
            theme: 'colored',
            position: 'top-right'
          });
        } else {
          toast.error('Erro ao editar a permissão!', {
            theme: 'colored',
            position: 'top-right'
          });

          for (let key in erro.response.data) {
            setError(key, {
              type: 'custom',
              message: erro.response.data[key]
            });
          }
        }
      });
  };

  return (
    <Fragment>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="g-3">
            <Col xs={12}>
              <Card>
                <Card.Body>
                  <Row className="flex-between-center">
                    <Col md>
                      <h5 className="mb-2 mb-md-0">
                        Permissão - {permissao?.funcao_sistema?.codigo}
                      </h5>
                    </Col>
                    <Col xs="auto">
                      <Button
                        size="sm"
                        variant="link"
                        type="button"
                        className="text-secondary me-2"
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        Voltar
                      </Button>
                      <Button
                        size="sm"
                        variant="falcon-danger"
                        className="me-3"
                        onClick={() => setShowModalExcluir(true)}
                      >
                        Excluir permissão
                      </Button>
                      <Button size="sm" variant="primary" type="submit">
                        Salvar alterações
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={8}>
              <InfoForm funcoesSistema={funcoesSistema} permissao={permissao} />
            </Col>
            <Col lg={4}>
              <div className="sticky-sidebar">
                <LogForm permissao={permissao} />
              </div>
            </Col>
          </Row>
        </Form>
      </FormProvider>
      <ModalExcluirPermissao
        show={showModalExcluir}
        onHide={() => setShowModalExcluir(false)}
        permissao={permissao}
      />
    </Fragment>
  );
};

export default PermissaoForm;

/* eslint-disable react/prop-types */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';

const NovoInfoPlanilha = ({inserePlanilha}) => {
  const {
    register,
    setValue,
    formState: { errors }
  } = useFormContext();        

  return (
    <Card className="mb-1">
      <Card.Header as="h5" className="bg-body-tertiary">
        Arquivo
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="7">
            <Form.Group>
              <Form.Label>Planilha excel</Form.Label>
              <Form.Control
                type="file" 
                accept='.xlsx'               
                isInvalid={!!errors.planilha}                
                onChange={e => {
                  setValue('planilha', e.target.files[0]);
                  inserePlanilha(e.target.files[0])
                }}
              />              
              <Form.Control.Feedback type="invalid" id='planilha'>
                {errors.planilha?.message}
              </Form.Control.Feedback>
            </Form.Group>
            <br />
            <h6>
              A planilha deve seguir o modelo abaixo:
            </h6>
            <Link
              to="https://rcitadm.com.br/media/PLANILHA_MODELO_PESSOAS.xlsx"                           
              className="me-3 mb-3 btn btn-outline-primary"              
              target="_blank" 
              download              
            >              
                <FontAwesomeIcon icon="file-download" />              
              <span className="ps-1">
                PLANILHA_MODELO_PESSOAS.xlsx
              </span>
            </Link>             
            <table>
              <tr>
                <th><h6>Observações:</h6></th>              
              </tr>             
              <tr>
                <td><h6>* A coluna Estado deve conter apenas a sigla do estado escolhido. Exemplo: CE</h6><h6>* A coluna Tipo de Usuário deve conter a mesma descrição de um dos valores cadastros no formulário de Pessoas/Tipos. Exemplo: Consultor</h6></td>                            
              </tr>
            </table>          
                   
          </Col>          
        </Row>
      </Card.Body>      
    </Card>
  );
};

export default NovoInfoPlanilha;

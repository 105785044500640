/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import MaskedInput from 'react-text-mask';

const EnderecoForm = ({ cliente }) => {
  const {
    register,
    setValue,
    formState: { errors }
  } = useFormContext();

  const [cep, setCep] = useState('');

  useEffect(() => {
    setValue('pais', cliente.pais);
    setValue('estado', cliente.estado);
    setValue('cep', cliente.cep);
    setCep(cliente.cep);
    setValue('cidade', cliente.cidade);
    setValue('bairro', cliente.bairro);
    setValue('logradouro', cliente.logradouro);
    setValue('complemento', cliente.complemento);
  }, [cliente]);

  const handleChangeCep = e => {
    setCep(e.target.value);
    setValue('cep', e.target.value);
  };

  return (
    <Card className="mb-3">
      <Card.Header as="h5" className="bg-body-tertiary">
        Endereço
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="3">
            <Form.Group>
              <Form.Label>País</Form.Label>
              <Form.Control
                type="text"
                placeholder="País do cliente"
                isInvalid={!!errors.pais}
                onChange={e => {
                  setValue('pais', e.target.value);
                }}
                {...register('pais')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.pais?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="2">
            <Form.Group>
              <Form.Label>Estado</Form.Label>
              <Form.Control
                type="text"
                maxLength={2}
                placeholder="UF"
                isInvalid={!!errors.estado}
                onChange={e => {
                  setValue('estado', e.target.value);
                }}
                {...register('estado')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.estado?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="3">
            <Form.Group>
              <Form.Label>CEP</Form.Label>
              <Form.Control
                hidden
                type="text"
                placeholder="CEP do cliente"
                isInvalid={!!errors.cep}
                {...register('cep')}
              />
              <MaskedInput
                value={cep == null ? '' : cep}
                // eslint-disable-next-line prettier/prettier
                mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                className="form-control"
                guide={false}
                placeholder="CEP do cliente"
                onChange={handleChangeCep}
              />
              <Form.Control.Feedback type="invalid">
                {errors.cep?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <Form.Label>Cidade</Form.Label>
              <Form.Control
                type="text"
                placeholder="Cidade do cliente"
                isInvalid={!!errors.cidade}
                onChange={e => {
                  setValue('cidade', e.target.value);
                }}
                {...register('cidade')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.cidade?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="3">
            <Form.Group>
              <Form.Label>Bairro</Form.Label>
              <Form.Control
                type="text"
                placeholder="Bairro do cliente"
                isInvalid={!!errors.bairro}
                onChange={e => {
                  setValue('bairro', e.target.value);
                }}
                {...register('bairro')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.bairro?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="5">
            <Form.Group>
              <Form.Label>Logradouro</Form.Label>
              <Form.Control
                type="text"
                placeholder="Logradouro do cliente"
                isInvalid={!!errors.logradouro}
                onChange={e => {
                  setValue('logradouro', e.target.value);
                }}
                {...register('logradouro')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.logradouro?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <Form.Label>Complemento (opcional)</Form.Label>
              <Form.Control
                type="text"
                placeholder="Complemento do cliente"
                isInvalid={!!errors.complemento}
                onChange={e => {
                  setValue('complemento', e.target.value);
                }}
                {...register('complemento')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.complemento?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default EnderecoForm;

/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import MaskedInput from 'react-text-mask';

const NovoInfoForm = ({ tiposUsuario, cpfVazio, cnpjVazio }) => {
  const {
    register,
    setValue,
    formState: { errors }
  } = useFormContext();

  const [listaTiposUsuario, setListaTiposUsuario] = useState([]);
  const [cpf, setCpf] = useState('');
  const [cnpj, setCnpj] = useState('');

  useEffect(() => {
    setListaTiposUsuario(tiposUsuario);
    setValue('tipo_usuario', 0);
    setCpf(cpfVazio);
  }, [tiposUsuario, cpfVazio]);

  const handleChangeCpf = e => {
    setCpf(e.target.value);
    setValue('cpf', e.target.value);
  };

  const handleChangeCnpj = e => {
    setCnpj(e.target.value);
    setValue('cnpj', e.target.value);
  };

  return (
    <Card className="mb-1">
      <Card.Header as="h5" className="bg-body-tertiary">
        Informações Gerais
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="7">
            <Form.Group>
              <Form.Label>Nome completo</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nome completo do usuário"
                isInvalid={!!errors.nome_completo}
                {...register('nome_completo')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.nome_completo?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="5">
            <Form.Group>
              <Form.Label>Nome reduzido</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nome reduzido do usuário"
                isInvalid={!!errors.nome_reduzido}
                {...register('nome_reduzido')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.nome_reduzido?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col> 
          <Col md="4">
            <Form.Group>
              <Form.Label>Data de nascimento</Form.Label>
              <Form.Control
                type="date"
                placeholder="Data de nascimento do usuário"
                isInvalid={!!errors.data_nascimento}
                {...register('data_nascimento')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.data_nascimento?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>         
          <Col md="4">
            <Form.Group>
              <Form.Label>CPF</Form.Label>
              <Form.Control
                hidden
                type="text"
                placeholder="CPF do usuário"
                {...register('cpf')}
                isInvalid={!!errors.cpf}
              />
              <MaskedInput
                value={cpf == null ? '' : cpf}
                // eslint-disable-next-line prettier/prettier
                mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                className="form-control"
                guide={false}
                placeholder="CPF do usuário"
                onChange={handleChangeCpf}
              />
              <Form.Control.Feedback type="invalid">
                {errors.cpf?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <Form.Label>RG</Form.Label>
              <Form.Control
                type="text"
                placeholder="1111111111-1 SSP/CE"
                isInvalid={!!errors.rg}
                {...register('rg')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.rg?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="8">
            <Form.Group>
              <Form.Label>Razão social</Form.Label>
              <Form.Control
                type="text"
                placeholder="Razão social do usuário"
                isInvalid={!!errors.razao_social}
                {...register('razao_social')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.razao_social?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>          
          <Col md="4">
            <Form.Group>
              <Form.Label>CNPJ</Form.Label>
              <Form.Control
                hidden
                type="text"
                placeholder="CNPJ do usuário"
                {...register('cnpj')}
                isInvalid={!!errors.cnpj}
              />
              <MaskedInput
                value={cnpj == null ? '' : cnpj}
                // eslint-disable-next-line prettier/prettier
                mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/,'-', /\d/, /\d/]}
                className="form-control"
                guide={false}
                placeholder="CNPJ do usuário"
                onChange={handleChangeCnpj}
              />
              <Form.Control.Feedback type="invalid">
                {errors.cnpj?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="8">
            <Form.Group>
              <Form.Label>Tipo de Usuário</Form.Label>
              <Form.Select
                name="tipo_usuario"
                isInvalid={!!errors.tipo_usuario}
                {...register('tipo_usuario')}
              >
                <option value={0} key={0}>
                  {'Selecione o tipo de usuário'}
                </option>
                {listaTiposUsuario != null ? (
                  listaTiposUsuario.map(item => (
                    <option value={item.id} key={item.id}>
                      {item.descricao}
                    </option>
                  ))
                ) : (
                  <option value={0} key={0} disabled>
                    {'Não existem tipos de usuário'}
                  </option>
                )}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.tipo_usuario?.message}
              </Form.Control.Feedback>
            </Form.Group>            
          </Col>
          <Col md="4">
            <Form.Group>
              <Form.Label>Data de início na empresa</Form.Label>
              <Form.Control
                type="date"
                placeholder="Data de início do usuário na empresa"
                isInvalid={!!errors.data_inicio_empresa}
                {...register('data_inicio_empresa')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.data_inicio_empresa?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col> 
        </Row>
      </Card.Body>
    </Card>
  );
};

export default NovoInfoForm;

/* eslint-disable react/prop-types */
import Flex from 'components/common/Flex';
import React, { useEffect } from 'react';
import { Card, Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const NovaSenhaForm = () => {
  const {
    register,
    setValue,
    formState: { errors }
  } = useFormContext();

  useEffect(() => {
    setValue('password', null);
    setValue('passwordConfirm', null);
  }, []);

  return (
    <Card className="mb-3">
      <Card.Header as="h5">Segurança (Opcional)</Card.Header>
      <Card.Body className="bg-body-tertiary">
        <Form.Group className="mb-3">
          <Flex className="flex-between-center">
            <Form.Label>Nova senha</Form.Label>
          </Flex>
          <Form.Control
            type="password"
            placeholder="Nova senha do usuário"
            isInvalid={!!errors.password}
            onChange={e => setValue('password', e.target.value)}
            {...register('password')}
          />
          <Form.Control.Feedback type="invalid">
            {errors.password?.message}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group className="mb-5">
          <Flex className="flex-between-center">
            <Form.Label>Confirmar senha</Form.Label>
          </Flex>
          <Form.Control
            type="password"
            placeholder="Confirmar senha do usuário"
            isInvalid={!!errors.passwordConfirm}
            onChange={e => setValue('passwordConfirm', e.target.value)}
            {...register('passwordConfirm')}
          />
          <Form.Control.Feedback type="invalid">
            {errors.passwordConfirm?.message}
          </Form.Control.Feedback>
        </Form.Group>
      </Card.Body>
    </Card>
  );
};

export default NovaSenhaForm;

import http from 'components/http';
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Form, Row, Card, Button } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import InfoForm from './InfoForm';
import LogForm from './LogForm';
import ModalExcluirTipoUsuario from './ModalExcluirTipo';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const schema = yup
  .object({
    descricao: yup.string().required('Esse campo é obrigatório')
  })
  .required();

const TiposDeUsuarioForm = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const [tipoUsuario, setTipoUsuario] = useState({
    id: '',
    descricao: '',
    usuario_insert: '',
    usuario_update: '',
    data_hora_insert: '',
    data_hora_update: ''
  });

  const [showModalExcluir, setShowModalExcluir] = useState(false);

  useEffect(() => {
    http
      .get('usuarios/tipos/' + id)
      .then(resposta => {
        setTipoUsuario(resposta.data);
      })
      .catch(erro => {
        console.log(erro);

        if (erro.request.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          navigate('/auth/entrar');
        } else if (erro.request.status === 403) {
          toast.error('Você não possui permissão para aquela ação', {
            theme: 'colored',
            position: 'top-right'
          });

          navigate('/');
        } else {
          toast.error('Erro ao exibir o tipo de usuário', {
            theme: 'colored',
            position: 'top-right'
          });

          navigate('/usuarios/tipos');
        }
      });
  }, []);

  const methods = useForm({
    resolver: yupResolver(schema)
  });

  const { handleSubmit } = methods;

  const onSubmit = data => {
    http
      .request({
        url: 'usuarios/tipos/' + tipoUsuario.id,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      })
      .then(response => {
        setTipoUsuario(response.data);

        toast.success('Tipo de usuário editado com sucesso!', {
          theme: 'colored',
          position: 'top-right'
        });
      })
      .catch(erro => {
        console.log(erro);
        if (erro.request.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          navigate('/auth/entrar');
        } else if (erro.request.status === 403) {
          toast.error('Você não possui permissão para essa ação', {
            theme: 'colored',
            position: 'top-right'
          });
        } else {
          toast.error('Erro ao editar o tipo de usuário!', {
            theme: 'colored',
            position: 'top-right'
          });
        }
      });
  };

  return (
    <Fragment>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="g-3">
            <Col xs={12}>
              <Card>
                <Card.Body>
                  <Row className="flex-between-center">
                    <Col md>
                      <h5 className="mb-2 mb-md-0">
                        Tipo de Usuário - {tipoUsuario.descricao}
                      </h5>
                    </Col>
                    <Col xs="auto">
                      <Button
                        size="sm"
                        variant="link"
                        type="button"
                        className="text-secondary me-2"
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        Voltar
                      </Button>
                      <Button
                        size="sm"
                        variant="falcon-danger"
                        className="me-3"
                        onClick={() => setShowModalExcluir(true)}
                      >
                        Excluir tipo de usuário
                      </Button>
                      <Button size="sm" variant="primary" type="submit">
                        Salvar alterações
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={8}>
              <InfoForm tipoUsuario={tipoUsuario} />
            </Col>
            <Col lg={4}>
              <div className="sticky-sidebar">
                <LogForm tipoUsuario={tipoUsuario} />
              </div>
            </Col>
          </Row>
        </Form>
      </FormProvider>
      <ModalExcluirTipoUsuario
        show={showModalExcluir}
        onHide={() => setShowModalExcluir(false)}
        tipoUsuario={tipoUsuario}
      />
    </Fragment>
  );
};

export default TiposDeUsuarioForm;

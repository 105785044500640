/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import MaskedInput from 'react-text-mask';

const NovoInfoForm = ({ clientes, cnpjVazio, insereImagem }) => {
  const {
    register,
    setValue,
    formState: { errors }
  } = useFormContext();

  const [listaClientes, setListaClientes] = useState([]);
  const [cnpj, setCnpj] = useState('');

  useEffect(() => {
    setListaClientes(clientes);
    setValue('cliente_agrupador', 0);
    setCnpj(cnpjVazio);
  }, [clientes, cnpjVazio]);

  const handleChangeCnpj = e => {
    setCnpj(e.target.value);
    setValue('cnpj', e.target.value);
  };

  return (
    <Card className="mb-1">
      <Card.Header as="h5" className="bg-body-tertiary">
        Informações Gerais
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="6">
            <Form.Group>
              <Form.Label>Razão social</Form.Label>
              <Form.Control
                type="text"
                placeholder="Razão social do cliente"
                isInvalid={!!errors.razao_social}
                {...register('razao_social')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.razao_social?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <Form.Label>Nome fantasia</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nome fantasia do cliente"
                isInvalid={!!errors.nome_fantasia}
                {...register('nome_fantasia')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.nome_fantasia?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="5">
            <Form.Group>
              <Form.Label>CNPJ</Form.Label>
              <Form.Control
                hidden
                type="text"
                placeholder="CNPJ do cliente"
                {...register('cnpj')}
                isInvalid={!!errors.cnpj}
              />
              <MaskedInput
                value={cnpj == null ? '' : cnpj}
                // eslint-disable-next-line prettier/prettier
                mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/,'-', /\d/, /\d/]}
                className="form-control"
                guide={false}
                placeholder="CNPJ do cliente"
                onChange={handleChangeCnpj}
              />
              <Form.Control.Feedback type="invalid">
                {errors.cnpj?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="7">
            <Form.Group>
              <Form.Label>Cliente agrupador (opcional)</Form.Label>
              <Form.Select
                name="cliente_agrupador"
                isInvalid={!!errors.cliente_agrupador}
                {...register('cliente_agrupador')}
              >
                <option value={0} key={0}>
                  {'Selecione o cliente'}
                </option>
                {listaClientes != null && listaClientes.length > 0 ? (
                  listaClientes.map(item => (
                    <option value={item.id} key={item.id}>
                      {item.cnpj} ({item.razao_social})
                    </option>
                  ))
                ) : (
                  <option value={0} key={1000000} disabled>
                    {'Não existem clientes'}
                  </option>
                )}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.cliente_agrupador?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <Form.Label>Logomarca (opcional)</Form.Label>
              <Form.Control
                type="file"
                onChange={e => insereImagem(e.target.files[0])}
                isInvalid={!!errors.logomarca}
              />
              <Form.Control.Feedback type="invalid">
                {errors.logomarca?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default NovoInfoForm;

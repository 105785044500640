import React, { Fragment, useEffect, useState } from 'react';
import { Col, Form, Row, Card, Button } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import http from 'components/http';
import { toast } from 'react-toastify';
import PontosFocaisForm from './PontosFocaisForm';
import ModalExcluirProjeto from './ModalExcluirProjeto';
import InfoForm from './InfoForm';
import GereciamentoForm from './GereciamentoForm';
import FinanceiroForm from './FinanceiroForm';
import LogForm from './LogForm';

const schema = yup
  .object({
    cliente: yup
      .number()
      .moreThan(0, 'Esse campo é obrigatório')
      .required('Esse campo é obrigatório'),
    nome: yup.string().required('Esse campo é obrigatório'),
    desc_escopo: yup.string().required(),
    desc_particularidades: yup.string().required(),
    tipo_atendimento: yup
      .string()
      .length(2, 'Campo inválido')
      .notOneOf([0], 'Esse campo é obrigatório')
      .required('Esse campo é obrigatório'),
    modalidade_atendimento: yup
      .string()
      .length(3, 'Campo inválido')
      .required('Esse campo é obrigatório'),
    dt_inicio: yup.string().required('Esse campo é obrigatório'),
    dt_fim_prev: yup.string().required('Esse campo é obrigatório'),
    dt_fim_real: yup.string().nullable().optional(),
    em_execucao: yup
      .string()
      .length(1, 'Campo inválido')
      .required('Esse campo é obrigatório'),
    condicao_pagamento: yup.string().optional().nullable(),
    valor_proposta: yup
      .number('Esse campo deve ser um número')
      .required('Esse campo é obrigatório'),
    valor_aceite: yup
      .number('Esse campo deve ser um número')
      .required('Esse campo é obrigatório'),
    qtde_horas: yup
      .number('Esse campo deve ser um número')
      .nullable()
      .optional(),
    dia_faturamento: yup
      .number('Esse campo deve ser um número')
      .max(31, 'Dia inválido')
      .required('Esse campo é obrigatório'),
    aliquota_iss: yup
      .number('Esse campo deve ser um número')
      .required('Esse campo é obrigatório'),
    nome_responsavel_adm: yup.string().required('Esse campo é obrigatório'),
    nome_gerente_projeto_rcit: yup
      .string()
      .required('Esse campo é obrigatório'),
    nome_gerente_projeto_cliente: yup
      .string()
      .required('Esse campo é obrigatório'),
    nome_diretor_projeto_cliente: yup.string().optional().nullable()
  })
  .required();

const ProjetoForm = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const [clientes, setClientes] = useState([]);
  const [docProposta, setDocProposta] = useState('');
  const [docContrato, setDocContrato] = useState('');
  const [mudaDocProposta, setmudaDocProposta] = useState(0);
  const [mudaDocContrato, setmudaDocContrato] = useState(0);
  const [cliente, setCliente] = useState('');
  const [projeto, setProjeto] = useState({
    cliente: '',
    nome: '',
    desc_escopo: '',
    desc_particularidades: '',
    tipo_atendimento: '',
    modalidade_atendimento: '',
    dt_inicio: '',
    dt_fim_prev: '',
    dt_fim_real: '',
    em_execucao: '',
    condicao_pagamento: '',
    valor_proposta: '',
    valor_aceite: '',
    qtde_horas: '',
    dia_faturamento: '',
    aliquota_iss: '',
    nome_responsavel_adm: '',
    nome_gerente_projeto_rcit: '',
    nome_gerente_projeto_cliente: '',
    nome_diretor_projeto_cliente: '',
    usuario_insert: '',
    usuario_update: '',
    data_hora_insert: '',
    data_hora_update: ''
  });

  const [showModalExcluir, setShowModalExcluir] = useState(false);

  const insereDocProposta = (doc, mudaDoc) => {
    setDocProposta(doc);
    setmudaDocProposta(mudaDoc);
  };

  const insereDocContrato = (doc, mudaDoc) => {
    setDocContrato(doc);
    setmudaDocContrato(mudaDoc);
  };

  const selecionaCliente = c => {
    clientes.map(obj => {
      if (obj.id == c) setCliente(obj);
      //console.log(obj);
    });
  };

  useEffect(() => {
    http
      .get('projetos/' + id)
      .then(resposta => {
        setProjeto(resposta.data);
        setCliente(resposta.data.cliente);
      })
      .catch(erro => {
        if (erro.request.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          navigate('/auth/entrar');
        } else if (erro.request.status === 403) {
          toast.error('Você não possui permissão para aquela ação', {
            theme: 'colored',
            position: 'top-right'
          });

          navigate('/');
          //window.location.reload();
        } else {
          toast.error('Erro ao listar o projeto', {
            theme: 'colored',
            position: 'top-right'
          });
          console.log(erro);
        }
      });

    http
      .get('clientes')
      .then(resposta => {
        setClientes(resposta.data);
      })
      .catch(erro => {
        if (erro.request.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          navigate('/auth/entrar');
        } else if (erro.request.status === 403) {
          toast.error('Você não possui permissão para aquela ação', {
            theme: 'colored',
            position: 'top-right'
          });

          navigate('/');
          //window.location.reload();
        } else {
          toast.error('Erro ao listar os clientes', {
            theme: 'colored',
            position: 'top-right'
          });
          console.log(erro);
        }
      });
  }, []);

  const methods = useForm({
    resolver: yupResolver(schema)
  });

  const { handleSubmit, setError, setValue } = methods;

  const onSubmit = data => {
    console.log(data);
    data['doc_proposta'] = docProposta;
    data['doc_contrato_comercial'] = docContrato;

    if (
      (mudaDocContrato == 1 && docContrato == '') ||
      (docProposta == '' && mudaDocProposta == 1)
    ) {
      toast.error('É obrigatório o envio dos anexos', {
        theme: 'colored',
        position: 'top-right'
      });
    } else {
      http
        .request({
          url: `projetos/${projeto.id}/${mudaDocProposta}/${mudaDocContrato}`,
          method: 'PUT',
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          data: data
        })
        .then(response => {
          setProjeto(response.data);

          toast.success('Projeto editado com sucesso!', {
            theme: 'colored',
            position: 'top-right'
          });
        })
        .catch(erro => {
          console.log(erro);
          if (erro.request.status === 401) {
            localStorage.removeItem('token');
            localStorage.removeItem('user');

            navigate('/auth/entrar');
          } else if (erro.request.status === 403) {
            toast.error('Você não possui permissão para essa ação', {
              theme: 'colored',
              position: 'top-right'
            });
          } else {
            toast.error('Erro ao editar o projeto!', {
              theme: 'colored',
              position: 'top-right'
            });

            for (let key in erro.response.data) {
              console.log(erro.response.data[key]);
              setError(key, {
                type: 'custom',
                message: erro.response.data[key]
              });
            }

            if (Object.keys(erro.response.data).includes('doc_proposta')) {
              toast.error(
                'Campo anexo da proposta: ' +
                  erro.response.data['doc_proposta'][0],
                {
                  theme: 'colored',
                  position: 'top-right'
                }
              );
            }
            if (
              Object.keys(erro.response.data).includes('doc_contrato_comercial')
            ) {
              toast.error(
                'Campo anexo do contrato comercial: ' +
                  erro.response.data['doc_contrato_comercial'][0],
                {
                  theme: 'colored',
                  position: 'top-right'
                }
              );
            }
          }
        });
    }
  };

  return (
    <Fragment>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="g-3">
            <Col xs={12}>
              <Card>
                <Card.Body>
                  <Row className="flex-between-center">
                    <Col md>
                      <h5 className="mb-2 mb-md-0">
                        Projeto - {projeto?.nome}
                      </h5>
                    </Col>
                    <Col xs="auto">
                      <Button
                        variant="link"
                        className="text-secondary fw-medium p-0 me-3"
                        type="button"
                        onClick={() => navigate(-1)}
                      >
                        Voltar
                      </Button>
                      <Button
                        size="sm"
                        variant="falcon-danger"
                        className="me-3"
                        onClick={() => setShowModalExcluir(true)}
                      >
                        Excluir projeto
                      </Button>
                      <Button size="sm" variant="primary" type="submit">
                        Salvar alterações
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={8}>
              <InfoForm
                clientes={clientes}
                projeto={projeto}
                insereDocContrato={insereDocContrato}
                insereDocProposta={insereDocProposta}
                selecionaCliente={selecionaCliente}
              />
              <PontosFocaisForm cliente={cliente} />
              <FinanceiroForm projeto={projeto} />
              <GereciamentoForm projeto={projeto} />
            </Col>
            <Col lg={4}>
              <div className="sticky-sidebar">
                <LogForm projeto={projeto} />
              </div>
            </Col>
          </Row>
        </Form>
      </FormProvider>
      <ModalExcluirProjeto
        show={showModalExcluir}
        onHide={() => setShowModalExcluir(false)}
        projeto={projeto}
      />
    </Fragment>
  );
};

export default ProjetoForm;

import React from 'react';
import { Col, Form, Row, Card, Button } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import NovoInfoForm from './NovoInfoForm';
import { useNavigate } from 'react-router-dom';
import http from 'components/http';
import { toast } from 'react-toastify';

const schema = yup
  .object({
    versao: yup
      .string()
      .min(14)
      .required('Esse campo é obrigatório'),
    descricao: yup
      .string()
      .required('Esse campo é obrigatório'),
  })
  .required();

const NovaVersaoForm = () => {
  const navigate = useNavigate();

  const submittedValues = {};

  const methods = useForm({
    resolver: yupResolver(schema)
  });

  const { handleSubmit, reset, setError } = methods;

  const onSubmit = data => {
    http
      .request({
        url: 'versoes',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      })
      .then(() => {
        const keys = Object.keys(data);
        for (const key of keys) {
          submittedValues[key] = '';
        }
        reset({ ...submittedValues });

        toast.success('Versão cadastrada com sucesso!', {
          theme: 'colored',
          position: 'top-right'
        });
      })
      .catch(erro => {
        console.log(erro);
        if (erro.request.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          navigate('/auth/entrar');
        } else if (erro.request.status === 403) {
          toast.error('Você não possui permissão para essa ação', {
            theme: 'colored',
            position: 'top-right'
          });
        } else {
          toast.error('Erro ao cadastrar a versão!', {
            theme: 'colored',
            position: 'top-right'
          });

          for (let key in erro.response.data) {
            setError(key, {
              type: 'custom',
              message: erro.response.data[key]
            });
          }
        }
      });
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="g-3">
          <Col xs={12}>
            <Card>
              <Card.Body>
                <Row className="flex-between-center">
                  <Col md>
                    <h5 className="mb-2 mb-md-0">Nova Versão</h5>
                  </Col>
                  <Col xs="auto">
                    <Button
                      variant="link"
                      className="text-secondary fw-medium p-0 me-3"
                      type="button"
                      onClick={() => navigate(-1)}
                    >
                      Voltar
                    </Button>
                    <Button variant="primary" type="submit">
                      Adicionar
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={12}>
            <NovoInfoForm />
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};

export default NovaVersaoForm;

import http from 'components/http';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Footer = () => {

  const [versao, setVersao] = useState('');
  const navigate = useNavigate();
  
  useEffect(() => {
    http
      .get('versoes/ultima')
      .then(resposta => {
        setVersao(resposta.data);
      })
      .catch(erro => {
        if (erro.request.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          navigate('/auth/entrar');
        } else if (erro.request.status === 403) {
          toast.error('Você não possui permissão para aquela ação', {
            theme: 'colored',
            position: 'top-right'
          });

          navigate('/');
          //window.location.reload();
        } else {
          console.log(erro);
          toast.error('Erro ao listar a última versão', {
            theme: 'colored',
            position: 'top-right'
          });
        }
      });
  }, []);

  return (
    <footer className="footer">
      <Row className="justify-content-between text-center fs--1 mt-4 mb-3">
        <Col sm="auto">
          <p className="mb-0 text-600">
            <br className="d-sm-none" /> {new Date().getFullYear()} &copy;{' '}
            <a href="https://rcitbrasil.com.br">RCiT Tecnologia</a>
          </p>
        </Col>
        <Col sm="auto">
          <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  navigate('/versoes');
                }}
              >
              <span className="text-muted">v{versao.versao}</span>
          </a>        
        </Col>
      </Row>
    </footer>
  );
}
export default Footer;

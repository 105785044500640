/* eslint-disable react/prop-types */
import Flex from 'components/common/Flex';
import React from 'react';
import { Card, Form, Table } from 'react-bootstrap';
import { dataFormatada } from 'helpers/utils';

const PontosFocaisForm = ({ cliente }) => {
  if (cliente != null && cliente != '') {
    return (
      <Card className="mb-1">
        <Card.Header as="h5" className="bg-body-tertiary">
          Pontos Focais
        </Card.Header>
        <Card.Body>
          <Form.Group className="mb-3">
            <Table responsive>
              <tr>
                <th>Nome</th>
                <th>Setor</th>
                <th>Email</th>
                <th>Celular</th>
              </tr>
              <tr>
                <td>{cliente.nome_contato_1}</td>
                <td>{cliente.setor_contato_1}</td>
                <td>{cliente.email_contato_1}</td>
                <td>{cliente.celular_contato_1}</td>
              </tr>
              <tr>
                <td>{cliente?.nome_contato_2}</td>
                <td>{cliente?.setor_contato_2}</td>
                <td>{cliente?.email_contato_2}</td>
                <td>{cliente?.celular_contato_2}</td>
              </tr>
              <tr>
                <td>{cliente?.nome_contato_3}</td>
                <td>{cliente?.setor_contato_3}</td>
                <td>{cliente?.email_contato_3}</td>
                <td>{cliente?.celular_contato_3}</td>
              </tr>
            </Table>
          </Form.Group>
        </Card.Body>
      </Card>
    );
  } else {
    return (
      <Card>
        <Card.Header as="h5" className="bg-body-tertiary">
          Pontos Focais
        </Card.Header>
        <Card.Body>
          <Form.Group className="mb-3">
            <Table responsive>
              <tr>Selecione um cliente para listagem dos pontos focais</tr>
            </Table>
          </Form.Group>
        </Card.Body>
      </Card>
    );
  }
};

export default PontosFocaisForm;

/* eslint-disable react/prop-types */
import Flex from 'components/common/Flex';
import React from 'react';
import { Card, Form } from 'react-bootstrap';
import { dataFormatada } from 'helpers/utils';

const LogForm = ({ tipoUsuario }) => {
  return (
    <Card>
      <Card.Header as="h5">Log</Card.Header>
      <Card.Body className="bg-body-tertiary">
        <Form.Group className="mb-3">
          <Flex className="flex-between-center">
            <Form.Label>Cadastrado pelo usuário</Form.Label>
          </Flex>
          <Form.Control
            type="text"
            name="usuario_insert"
            id="usuario_insert"
            value={
              tipoUsuario.usuario_insert != ''
                ? `${tipoUsuario.usuario_insert.cpf} / ${tipoUsuario.usuario_insert.nome_reduzido}`
                : ''
            }
            disabled
          />
        </Form.Group>
        <Form.Group className="mb-5">
          <Flex className="flex-between-center">
            <Form.Label>Cadastrado em</Form.Label>
          </Flex>
          <Form.Control
            type="text"
            name="datahora_insert"
            id="datahora_insert"
            value={
              tipoUsuario.data_hora_insert != ''
                ? dataFormatada(tipoUsuario.data_hora_insert, true)
                : ''
            }
            disabled
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Flex className="flex-between-center">
            <Form.Label>Editado pelo usuário</Form.Label>
          </Flex>
          <Form.Control
            type="text"
            name="usuario_edit"
            id="usuario_edit"
            value={
              tipoUsuario.usuario_update != '' &&
              tipoUsuario.usuario_update != null
                ? `${tipoUsuario.usuario_update.cpf} / ${tipoUsuario.usuario_update.nome_reduzido}`
                : ''
            }
            disabled
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Flex className="flex-between-center">
            <Form.Label>Editado em</Form.Label>
          </Flex>
          <Form.Control
            type="text"
            name="datahora_edit"
            id="datahora_edit"
            value={
              tipoUsuario.data_hora_update != '' &&
              tipoUsuario.data_hora_update != null
                ? dataFormatada(tipoUsuario.data_hora_update, true)
                : ''
            }
            disabled
          />
        </Form.Group>
      </Card.Body>
    </Card>
  );
};

export default LogForm;

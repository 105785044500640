/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const InfoForm = ({ tarefas, registro }) => {
  const {
    register,
    setValue,
    formState: { errors }
  } = useFormContext();

  useEffect(() => {
    setValue('usuario', registro?.usuario?.id);
    setValue('tarefa', registro?.tarefa?.id);    
  }, [tarefas, registro]);

  return (
    <Card className="mb-1">
      <Card.Header as="h5" className="bg-body-tertiary">
        Informações Gerais
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="12">
            <Form.Group>
              <Form.Label>Descrição da tarefa</Form.Label>
              <Form.Select
                name="tarefa"
                isInvalid={!!errors.tarefa}
                onChange={e => {
                  setValue('tarefa', e.target.value);
                }}
                {...register('tarefa')}
              >
                {tarefas != null && tarefas.length > 0 ? (
                  <option value={0} key={0}>
                    {'Selecione uma tarefa'}
                  </option>
                ) : (
                  <option hidden value={-1} key={0}></option>
                )}

                {tarefas != null && tarefas.length > 0 ? (
                  tarefas.map(item => (
                    <option value={item.id} key={item.id}>
                      {item.descricao}
                    </option>
                  ))
                ) : (
                  <option value={0} key={0} disabled>
                    {'Não existem tarefas cadastradas'}
                  </option>
                )}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.tarefa?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>          
        </Row>
      </Card.Body>
    </Card>
  );
};

export default InfoForm;

import http from 'components/http';
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Form, Row, Card, Button } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import InfoForm from './InfoForm';
import LogForm from './LogForm';
import ModalExcluirCliente from './ModalExcluirCliente';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ContatoForm from './ContatoForm';
import EnderecoForm from './EnderecoForm';

const schema = yup
  .object({
    cliente_agrupador: yup.string().optional().nullable(),
    cnpj: yup
      .string()
      .required('Esse campo é obrigatório')
      .length(18, 'CNPJ inválido'),
    razao_social: yup.string().required('Esse campo é obrigatório'),
    nome_fantasia: yup.string().required('Esse campo é obrigatório'),
    centro_custos: yup.string().optional().nullable(),
    complemento: yup.string(),
    cep: yup.string().min(10, 'Esse campo deve ter no mínimo 10 caracteres'),
    bairro: yup.string().required('Esse campo é obrigatório'),
    cidade: yup.string().required('Esse campo é obrigatório'),
    estado: yup
      .string()
      .required('Esse campo é obrigatório')
      .length(2, 'Estado não existe'),
    pais: yup.string().required('Esse campo é obrigatório'),
    nome_contato_1: yup.string().required('Esse campo é obrigatório'),
    email_contato_1: yup
      .string()
      .email('Email inválido')
      .required('Esse campo é obrigatório'),
    celular_contato_1: yup
      .string()
      .required('Esse campo é obrigatório')
      .min(8, 'Esse campo deve ter no mínimo 8 caracteres'),
    fone_contato_1: yup
      .string()
      .required('Esse campo é obrigatório')
      .min(8, 'Esse campo deve ter no mínimo 8 caracteres'),
    setor_contato_1: yup.string().required('Esse campo é obrigatório'),
    nome_contato_2: yup.string().optional().nullable(),
    email_contato_2: yup.string().email('Email inválido').optional().nullable(),
    celular_contato_2: yup.string().optional().nullable(),
    fone_contato_2: yup.string().optional().nullable(),
    setor_contato_2: yup.string().optional().nullable(),
    nome_contato_3: yup.string().optional().nullable(),
    email_contato_3: yup.string().email('Email inválido').optional().nullable(),
    celular_contato_3: yup.string().optional().nullable(),
    fone_contato_3: yup.string().optional().nullable(),
    setor_contato_3: yup.string().optional().nullable()
  })
  .required();

const ClienteForm = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const [logomarca, setLogomarca] = useState('');
  const [mudaLogomarca, setMudaLogomarca] = useState(0);
  const [clientes, setClientes] = useState([]);
  const [cliente, setCliente] = useState({
    id: '',
    cliente_agrupador: '',
    cnpj: '',
    logomarca: '',
    razao_social: '',
    nome_fantasia: '',
    centro_custos: '',
    cep: '',
    logradouro: '',
    complemento: '',
    bairro: '',
    cidade: '',
    estado: '',
    pais: '',
    nome_contato_1: '',
    email_contato_1: '',
    celular_contato_1: '',
    fone_contato_1: '',
    setor_contato_1: '',
    nome_contato_2: '',
    email_contato_2: '',
    celular_contato_2: '',
    fone_contato_2: '',
    setor_contato_2: '',
    nome_contato_3: '',
    email_contato_3: '',
    celular_contato_3: '',
    fone_contato_3: '',
    setor_contato_3: '',
    usuario_insert: '',
    usuario_update: '',
    data_hora_insert: '',
    data_hora_update: ''
  });

  const [showModalExcluir, setShowModalExcluir] = useState(false);

  useEffect(() => {
    http
      .get('clientes/' + id)
      .then(resposta => {
        setCliente(resposta.data);
      })
      .catch(erro => {
        console.log(erro);

        if (erro.request.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          navigate('/auth/entrar');
        } else if (erro.request.status === 403) {
          toast.error('Você não possui permissão para aquela ação', {
            theme: 'colored',
            position: 'top-right'
          });

          navigate('/');
        } else {
          toast.error('Erro ao exibir o cliente', {
            theme: 'colored',
            position: 'top-right'
          });

          navigate('/clientes');
        }
      });

    http
      .get('clientes')
      .then(resposta => {
        let listaClientes = [];
        listaClientes = resposta.data.filter(c => {
          if (c.id != id) return c;
        });
        setClientes(listaClientes);
      })
      .catch(erro => {
        console.log(erro);

        if (erro.request.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          navigate('/auth/entrar');
        } else if (erro.request.status === 403) {
          toast.error('Você não possui permissão para aquela ação', {
            theme: 'colored',
            position: 'top-right'
          });

          navigate('/');
        } else {
          toast.error('Erro ao listar os clientes', {
            theme: 'colored',
            position: 'top-right'
          });

          navigate('/clientes');
        }
      });
  }, []);

  const insereImagem = (img, mudaImg) => {
    setLogomarca(img);
    setMudaLogomarca(mudaImg);
  };

  const methods = useForm({
    resolver: yupResolver(schema)
  });

  const { handleSubmit, setError } = methods;

  const onSubmit = data => {
    if (data['cliente_agrupador'] == 0) data['cliente_agrupador'] = '';

    data['logomarca'] = logomarca;
    http
      .request({
        url: `clientes/${cliente.id}/${mudaLogomarca}`,
        method: 'PUT',
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: data
      })
      .then(response => {
        setCliente(response.data);

        toast.success('Cliente editado com sucesso!', {
          theme: 'colored',
          position: 'top-right'
        });
      })
      .catch(erro => {
        console.log(erro);
        if (erro.request.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          navigate('/auth/entrar');
        } else if (erro.request.status === 403) {
          toast.error('Você não possui permissão para essa ação', {
            theme: 'colored',
            position: 'top-right'
          });
        } else {
          toast.error('Erro ao editar cliente!', {
            theme: 'colored',
            position: 'top-right'
          });

          for (let key in erro.response.data) {
            setError(key, {
              type: 'custom',
              message: erro.response.data[key]
            });
          }
        }
      });
  };

  return (
    <Fragment>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="g-3">
            <Col xs={12}>
              <Card>
                <Card.Body>
                  <Row className="flex-between-center">
                    <Col md>
                      <h5 className="mb-2 mb-md-0">
                        Cliente - {cliente?.razao_social}
                      </h5>
                    </Col>
                    <Col xs="auto">
                      <Button
                        size="sm"
                        variant="link"
                        type="button"
                        className="text-secondary me-2"
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        Voltar
                      </Button>
                      <Button
                        size="sm"
                        variant="falcon-danger"
                        className="me-3"
                        onClick={() => setShowModalExcluir(true)}
                      >
                        Excluir cliente
                      </Button>
                      <Button size="sm" variant="primary" type="submit">
                        Salvar alterações
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={8}>
              <InfoForm
                clientes={clientes}
                cliente={cliente}
                insereImagem={insereImagem}
              />
              <EnderecoForm cliente={cliente} />
              <ContatoForm cliente={cliente} />
            </Col>
            <Col lg={4}>
              <div className="sticky-sidebar">
                <LogForm cliente={cliente} />
              </div>
            </Col>
          </Row>
        </Form>
      </FormProvider>
      <ModalExcluirCliente
        show={showModalExcluir}
        onHide={() => setShowModalExcluir(false)}
        cliente={cliente}
      />
    </Fragment>
  );
};

export default ClienteForm;

/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import MaskedInput from 'react-text-mask';

const InfoForm = ({ tiposUsuario, usuario }) => {
  const {
    register,
    setValue,
    formState: { errors }
  } = useFormContext();

  const [cpf, setCpf] = useState('');
  const [cnpj, setCnpj] = useState('');

  useEffect(() => {
    console.log(usuario);
    setValue('nome_completo', usuario.nome_completo);
    setValue('nome_reduzido', usuario.nome_reduzido);
    setValue('data_nascimento', usuario.data_nascimento);
    setValue('cpf', usuario.cpf);
    setCpf(usuario.cpf);
    setValue('rg', usuario.rg);
    setValue('razao_social', usuario.razao_social);    
    setValue('cnpj', usuario.cnpj);
    setCnpj(usuario.cnpj);
    setValue('tipo_usuario', usuario.tipo_usuario?.id);
    setValue('data_inicio_empresa', usuario.data_inicio_empresa);
  }, [tiposUsuario, usuario]);

  const handleChangeCpf = e => {
    setCpf(e.target.value);
    setValue('cpf', e.target.value);
  };

  const handleChangeCnpj = e => {
    setCnpj(e.target.value);
    setValue('cnpj', e.target.value);
  };

  return (
    <Card className="mb-3">
      <Card.Header as="h5" className="bg-body-tertiary">
        Informações Gerais
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="7">
            <Form.Group>
              <Form.Label>Nome completo</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nome completo do usuário"
                isInvalid={!!errors.nome_completo}
                onChange={e => {
                  setValue('nome_completo', e.target.value);
                }}
                {...register('nome_completo')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.nome_completo?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="5">
            <Form.Group>
              <Form.Label>Nome reduzido</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nome reduzido do usuário"
                isInvalid={!!errors.nome_reduzido}
                onChange={e => {
                  setValue('nome_reduzido', e.target.value);
                }}
                {...register('nome_reduzido')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.nome_reduzido?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <Form.Label>Data de nascimento</Form.Label>
              <Form.Control
                type="date"
                placeholder="Data de nascimento do usuário"
                isInvalid={!!errors.data_nascimento}
                onChange={e => {
                  setValue('data_nascimento', e.target.value);
                }}
                {...register('data_nascimento')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.data_nascimento?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col> 
          <Col md="4">
            <Form.Group>
              <Form.Label>CPF</Form.Label>
              <Form.Control
                hidden
                type="text"
                placeholder="CPF do usuário"
                {...register('cpf')}
                isInvalid={!!errors.cpf}
              />
              <MaskedInput
                value={cpf == null ? '' : cpf}
                // eslint-disable-next-line prettier/prettier
                mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                className="form-control"
                guide={false}
                placeholder="CPF do usuário"
                onChange={handleChangeCpf}
              />
              <Form.Control.Feedback type="invalid">
                {errors.cpf?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <Form.Label>RG</Form.Label>
              <Form.Control
                type="text"
                placeholder="RG do usuário"
                isInvalid={!!errors.rg}
                onChange={e => {
                  setValue('rg', e.target.value);
                }}
                {...register('rg')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.rg?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="8">
            <Form.Group>
              <Form.Label>Razão social</Form.Label>
              <Form.Control
                type="text"
                placeholder="Razão social do usuário"
                isInvalid={!!errors.razao_social}
                onChange={e => setValue('razao_social', e.target.value)}
                {...register('razao_social')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.razao_social?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>        
          <Col md="4">
            <Form.Group>
              <Form.Label>CNPJ</Form.Label>
              <Form.Control
                hidden
                type="text"
                placeholder="CNPJ do cliente"
                {...register('cnpj')}
                isInvalid={!!errors.cnpj}
              />
              <MaskedInput
                value={cnpj == null ? '' : cnpj}
                // eslint-disable-next-line prettier/prettier
                mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/,'-', /\d/, /\d/]}
                className="form-control"
                guide={false}
                placeholder="CNPJ do usuário"
                onChange={handleChangeCnpj}
              />
              <Form.Control.Feedback type="invalid">
                {errors.cnpj?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="8">
            <Form.Group>
              <Form.Label>Tipo de Usuário</Form.Label>
              <Form.Select
                name="tipo_usuario"
                isInvalid={!!errors.tipo_usuario}
                onChange={e => {
                  setValue('tipo_usuario', e.target.value);
                }}
                {...register('tipo_usuario')}
              >
                <option value={0} key={0}>
                  {'Selecione o tipo de usuário'}
                </option>
                {tiposUsuario != null ? (
                  tiposUsuario.map(item => (
                    <option value={item.id} key={item.id}>
                      {item.descricao}
                    </option>
                  ))
                ) : (
                  <option value={0} key={0} disabled>
                    {'Não existem tipos de usuário'}
                  </option>
                )}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.tipo_usuario?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <Form.Label>Data de início na empresa</Form.Label>
              <Form.Control
                type="date"
                placeholder="Data de início do usuário na empresa"
                onChange={e => {
                  setValue('data_inicio_empresa', e.target.value);
                }}
                isInvalid={!!errors.data_inicio_empresa}
                {...register('data_inicio_empresa')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.data_inicio_empresa?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col> 
        </Row>
      </Card.Body>
    </Card>
  );
};

export default InfoForm;

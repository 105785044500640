/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState } from 'react';
import { Card, Col, Form, Row, Button } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import MaskedInput from 'react-text-mask';

const InfoForm = ({
  clientes,
  projeto,
  insereDocContrato,
  insereDocProposta,
  selecionaCliente
}) => {
  const {
    register,
    setValue,
    formState: { errors }
  } = useFormContext();

  const [showInputDocProposta, setShowInputDocProposta] = useState(true);
  const [showInputDocContrato, setShowInputDocContrato] = useState(true);

  useEffect(() => {
    if (projeto?.doc_proposta != null && projeto?.doc_proposta != '')
      setShowInputDocProposta(false);

    if (
      projeto?.doc_contrato_comercial != null &&
      projeto?.doc_contrato_comercial != ''
    )
      setShowInputDocContrato(false);

    setValue('cliente', projeto.cliente.id);
    setValue('nome', projeto.nome);
    setValue('desc_escopo', projeto.desc_escopo);
    setValue('desc_particularidades', projeto.desc_particularidades);
    setValue('tipo_atendimento', projeto.tipo_atendimento);
    setValue('modalidade_atendimento', projeto.modalidade_atendimento);
    setValue('em_execucao', projeto.em_execucao);
    setValue('dt_inicio', projeto.dt_inicio);
    setValue('dt_fim_prev', projeto.dt_fim_prev);
    setValue('dt_fim_real', projeto.dt_fim_real);
  }, [clientes, projeto]);

  return (
    <Card className="mb-1">
      <Card.Header as="h5" className="bg-body-tertiary">
        Informações Gerais
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="6">
            <Form.Group>
              <Form.Label>Nome</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nome do projeto"
                isInvalid={!!errors.nome}
                onChange={e => setValue('nome', e.target.value)}
                {...register('nome')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.nome?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <Form.Label>Cliente</Form.Label>
              <Form.Select
                isInvalid={!!errors.cliente}
                {...register('cliente')}
                onChange={c => {
                  selecionaCliente(c.target.value);
                  setValue('cliente', c.target.value);
                }}
              >
                <option value={0} key={0}>
                  {'Selecione o cliente'}
                </option>
                {clientes != null && clientes.length > 0 ? (
                  clientes.map(item => (
                    <option value={item.id} key={item.id}>
                      {item.cnpj} ({item.razao_social})
                    </option>
                  ))
                ) : (
                  <option value={0} key={1000000} disabled>
                    {'Não existem clientes'}
                  </option>
                )}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.cliente?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <Form.Label>Descrição do escopo</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                type="text"
                placeholder="Descrição do escopo do projeto"
                isInvalid={!!errors.desc_escopo}
                onChange={e => setValue('desc_escopo', e.target.value)}
                {...register('desc_escopo')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.desc_escopo?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <Form.Label>Descrição das particularidades</Form.Label>
              <Form.Control
                as="textarea"
                type="text"
                rows={5}
                placeholder="Descrição das particularidades do projeto"
                onChange={e =>
                  setValue('desc_particularidades', e.target.value)
                }
                {...register('desc_particularidades')}
                isInvalid={!!errors.desc_particularidades}
              />
              <Form.Control.Feedback type="invalid">
                {errors.desc_particularidades?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md="6">
            <Form.Group>
              <Form.Label>Anexo da proposta</Form.Label>
              {showInputDocProposta === false ? (
                <Row>
                  <Col md="6">
                    <Button
                      size="sm"
                      variant="outline-primary"
                      className="form-control"
                      href={`https://rcitadm.com.br${projeto?.doc_proposta}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Visualizar
                    </Button>
                  </Col>
                  <Col md="6">
                    <Button
                      size="sm"
                      variant="falcon-danger"
                      className="form-control"
                      onClick={() => {
                        setShowInputDocProposta(true);
                        insereDocProposta('', 1);
                      }}
                    >
                      Alterar
                    </Button>
                  </Col>
                </Row>
              ) : (
                <Fragment>
                  <Form.Control
                    type="file"
                    onChange={e => insereDocProposta(e.target.files[0], 1)}
                    isInvalid={!!errors.doc_proposta}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.doc_proposta?.message}
                  </Form.Control.Feedback>
                </Fragment>
              )}
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <Form.Label>Anexo do contrato comercial</Form.Label>
              {showInputDocContrato === false ? (
                <Row>
                  <Col md="6">
                    <Button
                      size="sm"
                      variant="outline-primary"
                      className="form-control"
                      href={`https://rcitadm.com.br${projeto?.doc_contrato_comercial}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Visualizar
                    </Button>
                  </Col>
                  <Col md="6">
                    <Button
                      size="sm"
                      variant="falcon-danger"
                      className="form-control"
                      onClick={() => {
                        setShowInputDocContrato(true);
                        insereDocContrato('', 1);
                      }}
                    >
                      Alterar
                    </Button>
                  </Col>
                </Row>
              ) : (
                <Fragment>
                  <Form.Control
                    type="file"
                    onChange={e => insereDocContrato(e.target.files[0], 1)}
                    isInvalid={!!errors.doc_contrato_comercial}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.doc_contrato_comercial?.message}
                  </Form.Control.Feedback>
                </Fragment>
              )}
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <Form.Label>Tipo de atendimento</Form.Label>
              <Form.Select
                name="tipo_atendimento"
                isInvalid={!!errors.tipo_atendimento}
                onChange={e => setValue('tipo_atendimento', e.target.value)}
                {...register('tipo_atendimento')}
              >
                <option value={0} key={0}>
                  {'Selecione o tipo de atendimento'}
                </option>
                <option value={'TM'} key={1}>
                  {'TM - TIME MATERIAL'}
                </option>
                <option value={'FP'} key={2}>
                  {'FP - FIXED PRICE'}
                </option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.tipo_atendimento?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <Form.Label>Modalidade de atendimento</Form.Label>
              <Form.Control
                type="text"
                placeholder="Modalidade de atendimento do projeto"
                maxLength={3}
                isInvalid={!!errors.modalidade_atendimento}
                onChange={e =>
                  setValue('modalidade_atendimento', e.target.value)
                }
                {...register('modalidade_atendimento')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.modalidade_atendimento?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <Form.Label>Em execução</Form.Label>
              <Form.Select
                isInvalid={!!errors.em_execucao}
                onChange={e => setValue('em_execucao', e.target.value)}
                {...register('em_execucao')}
              >
                <option value={0} key={0}>
                  {'Selecione uma opção'}
                </option>
                <option value={'S'} key={1}>
                  {'SIM'}
                </option>
                <option value={'N'} key={2}>
                  {'NÃO'}
                </option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.em_execucao?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <Form.Label>Data inicial</Form.Label>
              <Form.Control
                type="date"
                placeholder="Data inicial do período"
                isInvalid={!!errors.dt_inicio}
                onChange={e => setValue('dt_inicio', e.target.value)}
                {...register('dt_inicio')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.dt_inicio?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <Form.Label>Data final prevista</Form.Label>
              <Form.Control
                type="date"
                placeholder="Data final prevista do projeto"
                isInvalid={!!errors.dt_fim_prev}
                onChange={e => setValue('dt_fim_prev', e.target.value)}
                {...register('dt_fim_prev')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.dt_fim_prev?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <Form.Label>Data final real (opcional)</Form.Label>
              <Form.Control
                type="date"
                placeholder="Data final real do projeto"
                isInvalid={!!errors.dt_fim_real}
                onChange={e => setValue('dt_fim_real', e.target.value)}
                {...register('dt_fim_real')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.dt_fim_real?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default InfoForm;

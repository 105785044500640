/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import MaskedInput from 'react-text-mask';

const InfoForm = ({ clientes, cliente, insereImagem }) => {
  const {
    register,
    setValue,
    formState: { errors }
  } = useFormContext();

  const [cnpj, setCnpj] = useState('');
  const [showInputLogomarca, setShowInputLogomarca] = useState(true);

  useEffect(() => {
    if (cliente?.logomarca != null && cliente?.logomarca != '')
      setShowInputLogomarca(false);

    setValue('razao_social', cliente.razao_social);
    setValue('nome_fantasia', cliente.nome_fantasia);
    setValue('cnpj', cliente.cnpj);
    setCnpj(cliente.cnpj);
    setValue('rg', cliente.rg);
    setValue(
      'cliente_agrupador',
      cliente.cliente_agrupador !== null ? cliente.cliente_agrupador.id : 0
    );
  }, [clientes, cliente]);

  const handleChangeCnpj = e => {
    setCnpj(e.target.value);
    setValue('cnpj', e.target.value);
  };

  return (
    <Card className="mb-3">
      <Card.Header as="h5" className="bg-body-tertiary">
        Informações Gerais
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="6">
            <Form.Group>
              <Form.Label>Razão social</Form.Label>
              <Form.Control
                type="text"
                placeholder="Razão social do cliente"
                isInvalid={!!errors.razao_social}
                onChange={e => setValue('razao_social', e.target.value)}
                {...register('razao_social')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.razao_social?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <Form.Label>Nome fantasia</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nome fantasia do cliente"
                isInvalid={!!errors.nome_fantasia}
                onChange={e => setValue('nome_fantasia', e.target.value)}
                {...register('nome_fantasia')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.nome_fantasia?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="5">
            <Form.Group>
              <Form.Label>CNPJ</Form.Label>
              <Form.Control
                hidden
                type="text"
                placeholder="CNPJ do cliente"
                {...register('cnpj')}
                isInvalid={!!errors.cnpj}
              />
              <MaskedInput
                value={cnpj == null ? '' : cnpj}
                // eslint-disable-next-line prettier/prettier
                mask={[/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/,'-', /\d/, /\d/]}
                className="form-control"
                guide={false}
                placeholder="CNPJ do cliente"
                onChange={handleChangeCnpj}
              />
              <Form.Control.Feedback type="invalid">
                {errors.cnpj?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="7">
            <Form.Group>
              <Form.Label>Cliente agrupador (opcional)</Form.Label>
              <Form.Select
                name="cliente_agrupador"
                isInvalid={!!errors.cliente_agrupador}
                onChange={e => setValue('cliente_agrupador', e.target.value)}
                {...register('cliente_agrupador')}
              >
                <option value={0} key={0}>
                  {'Selecione o cliente'}
                </option>
                {clientes != null && clientes.length > 0 ? (
                  clientes.map(item => (
                    <option value={item.id} key={item.id}>
                      {item.cnpj} ({item.razao_social})
                    </option>
                  ))
                ) : (
                  <option value={0} key={1000000} disabled>
                    {'Não existem clientes'}
                  </option>
                )}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.cliente_agrupador?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <Form.Label>Logomarca (opcional)</Form.Label>
              {showInputLogomarca === false ? (
                <Row>
                  <Col md="6">
                    <Button
                      size="sm"
                      variant="outline-primary"
                      className="form-control"
                      href={`https://rcitadm.com.br${cliente?.logomarca}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Visualizar
                    </Button>
                  </Col>
                  <Col md="6">
                    <Button
                      size="sm"
                      variant="falcon-danger"
                      className="form-control"
                      onClick={() => {
                        setShowInputLogomarca(true);
                        insereImagem('', 1);
                      }}
                    >
                      Alterar
                    </Button>
                  </Col>
                </Row>
              ) : (
                <Fragment>
                  <Form.Control
                    type="file"
                    onChange={e => insereImagem(e.target.files[0], 1)}
                    isInvalid={!!errors.logomarca}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.logomarca?.message}
                  </Form.Control.Feedback>
                </Fragment>
              )}
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default InfoForm;

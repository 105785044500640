import http from 'components/http';
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Form, Row, Card, Button } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import InfoForm from './InfoForm';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import NovaSenhaForm from './NovaSenhaForm';
import ContatoForm from './ContatoForm';
import EnderecoForm from './EnderecoForm';
import Banner from './Banner';

const schema = yup
  .object({
    password: yup
      .string()
      .optional()
      .nullable()
      .min(8, 'Esse campo deve ter no mínimo 8 caracteres'),
    passwordConfirm: yup
      .string()
      .nullable()
      .optional()
      .oneOf([yup.ref('password'), null, ''], 'As senhas não são iguais')
      .min(8, 'Esse campo deve ter no mínimo 8 caracteres'),
    cpf: yup
      .string()
      .required('Esse campo é obrigatório')
      .length(14, 'CPF inválido'),
    rg: yup
      .string()
      .required('Esse campo é obrigatório')
      .min(13, 'Esse campo deve ter no mínimo 13 caracteres'),
    cnpj: yup
      .string()
      .required('Esse campo é obrigatório')
      .length(18, 'CNPJ inválido'),
    razao_social: yup.string().required('Esse campo é obrigatório'),
    nome_completo: yup
      .string()
      .required('Esse campo é obrigatório')
      .matches('^[A-zÀ-ú ]+$', 'Esse campo deve conter apenas letras'),
    nome_reduzido: yup
      .string()
      .required('Esse campo é obrigatório')
      .matches('^[A-zÀ-ú ]+$', 'Esse campo deve conter apenas letras'),
    logradouro: yup.string().required('Esse campo é obrigatório'),
    complemento: yup.string(),
    cep: yup.string().min(10, 'Esse campo deve ter no mínimo 10 caracteres'),
    bairro: yup.string().required('Esse campo é obrigatório'),
    cidade: yup.string().required('Esse campo é obrigatório'),
    estado: yup
      .string()
      .required('Esse campo é obrigatório')
      .length(2, 'Estado não existe'),
    pais: yup.string().required('Esse campo é obrigatório'),
    email: yup
      .string()
      .email('Email inválido')
      .required('Esse campo é obrigatório'),
    celular: yup
      .string()
      .required('Esse campo é obrigatório')
      .min(8, 'Esse campo deve ter no mínimo 8 caracteres'),
    fone_1: yup.string().optional().nullable(),
    fone_2: yup.string().optional().nullable(),
    fone_3: yup.string().optional().nullable(),
    data_nascimento: yup.string().required('Esse campo é obrigatório'),
    data_inicio_empresa: yup.string().required('Esse campo é obrigatório')
  })
  .required();

const PerfilForm = () => {
  const navigate = useNavigate();

  const [usuario, setUsuario] = useState({
    id: '',
    tipo_usuario: '',
    cpf: '',
    rg: '',
    cnpj: '',
    razao_social: '',
    data_inicio_empresa: '', 
    nome_completo: '',
    nome_reduzido: '',
    logradouro: '',
    complemento: '',
    bairro: '',
    cidade: '',
    estado: '',
    pais: '',
    email: '',
    celular: '',
    fone_1: '',
    fone_2: '',
    fone_3: '',
    data_nascimento: '',
    usuario_insert: '',
    usuario_update: '',
    data_hora_insert: '',
    data_hora_update: ''
  });

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));

    http
      .get('usuarios/' + user.id)
      .then(resposta => {
        setUsuario(resposta.data);
      })
      .catch(erro => {
        console.log(erro);

        if (erro.request.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          navigate('/auth/entrar');
        } else if (erro.request.status === 403) {
          toast.error('Você não possui permissão para aquela ação', {
            theme: 'colored',
            position: 'top-right'
          });

          navigate('/');
        } else {
          toast.error('Erro ao exibir o usuário', {
            theme: 'colored',
            position: 'top-right'
          });

          navigate('/usuarios');
        }
      });
  }, []);

  const methods = useForm({
    resolver: yupResolver(schema)
  });

  const { handleSubmit, setError, setValue } = methods;

  const onSubmit = data => {
    data['tipo_usuario'] = usuario.tipo_usuario.id;

    http
      .request({
        url: 'usuarios/' + usuario.id,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      })
      .then(response => {
        setUsuario(response.data);
        setValue('password', null);
        setValue('passwordConfirm', null);

        toast.success('Usuário editado com sucesso!', {
          theme: 'colored',
          position: 'top-right'
        });
      })
      .catch(erro => {
        console.log(erro);
        if (erro.request.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          navigate('/auth/entrar');
        } else if (erro.request.status === 403) {
          toast.error('Você não possui permissão para essa ação', {
            theme: 'colored',
            position: 'top-right'
          });
        } else {
          toast.error('Erro ao editar usuário!', {
            theme: 'colored',
            position: 'top-right'
          });

          for (let key in erro.response.data) {
            setError(key, {
              type: 'custom',
              message: erro.response.data[key]
            });
          }
        }
      });
  };

  return (
    <Fragment>
      <Banner usuario={usuario} />
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="g-3">
            <Col xs={12}>
              <Card>
                <Card.Body>
                  <Row className="flex-between-center">
                    <Col md>
                      <h5 className="mb-2 mb-md-0">Perfil</h5>
                    </Col>
                    <Col xs="auto">
                      <Button
                        size="sm"
                        variant="link"
                        type="button"
                        className="text-secondary me-2"
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        Voltar
                      </Button>
                      <Button size="sm" variant="primary" type="submit">
                        Salvar alterações
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={8}>
              <InfoForm usuario={usuario} />
              <ContatoForm usuario={usuario} />
              <EnderecoForm usuario={usuario} />
            </Col>
            <Col lg={4}>
              <div className="sticky-sidebar">
                <NovaSenhaForm />
              </div>
            </Col>
          </Row>
        </Form>
      </FormProvider>
    </Fragment>
  );
};

export default PerfilForm;

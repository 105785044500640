/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import MaskedInput from 'react-text-mask';

const NovoInfoForm = ({
  clientes,
  insereDocContrato,
  insereDocProposta,
  selecionaCliente
}) => {
  const {
    register,
    setValue,
    formState: { errors }
  } = useFormContext();

  const [listaClientes, setListaClientes] = useState([]);

  useEffect(() => {
    setListaClientes(clientes);
    setValue('cliente', 0);
  }, [clientes]);

  return (
    <Card className="mb-1">
      <Card.Header as="h5" className="bg-body-tertiary">
        Informações Gerais
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="6">
            <Form.Group>
              <Form.Label>Nome</Form.Label>
              <Form.Control
                type="text"
                placeholder="Nome do projeto"
                isInvalid={!!errors.nome}
                {...register('nome')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.nome?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <Form.Label>Cliente</Form.Label>
              <Form.Select
                isInvalid={!!errors.cliente}
                onChange={c => {
                  selecionaCliente(c.target.value);
                  setValue('cliente', c.target.value);
                }}
              >
                <option value={0} key={0}>
                  {'Selecione o cliente'}
                </option>
                {listaClientes != null && listaClientes.length > 0 ? (
                  listaClientes.map(item => (
                    <option value={item.id} key={item.id}>
                      {item.cnpj} ({item.razao_social})
                    </option>
                  ))
                ) : (
                  <option value={0} key={1000000} disabled>
                    {'Não existem clientes'}
                  </option>
                )}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.cliente?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <Form.Label>Descrição do escopo</Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                type="text"
                placeholder="Descrição do escopo do projeto"
                isInvalid={!!errors.desc_escopo}
                {...register('desc_escopo')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.desc_escopo?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <Form.Label>Descrição das particularidades</Form.Label>
              <Form.Control
                as="textarea"
                type="text"
                rows={5}
                placeholder="Descrição das particularidades do projeto"
                {...register('desc_particularidades')}
                isInvalid={!!errors.desc_particularidades}
              />
              <Form.Control.Feedback type="invalid">
                {errors.desc_particularidades?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col md="6">
            <Form.Group>
              <Form.Label>Anexo da proposta</Form.Label>
              <Form.Control
                type="file"
                onChange={e => insereDocProposta(e.target.files[0])}
                isInvalid={!!errors.doc_proposta}
              />
              <Form.Control.Feedback type="invalid">
                {errors.doc_proposta?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <Form.Label>Anexo do contrato comercial</Form.Label>
              <Form.Control
                type="file"
                onChange={e => insereDocContrato(e.target.files[0])}
                isInvalid={!!errors.doc_contrato_comercial}
              />
              <Form.Control.Feedback type="invalid">
                {errors.doc_contrato_comercial?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <Form.Label>Tipo de atendimento</Form.Label>
              <Form.Select
                name="tipo_atendimento"
                isInvalid={!!errors.tipo_atendimento}
                {...register('tipo_atendimento')}
              >
                <option value={0} key={0}>
                  {'Selecione o tipo de atendimento'}
                </option>
                <option value={'TM'} key={1}>
                  {'TM - TIME MATERIAL'}
                </option>
                <option value={'FP'} key={2}>
                  {'FP - FIXED PRICE'}
                </option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.tipo_atendimento?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <Form.Label>Modalidade de atendimento</Form.Label>
              <Form.Control
                type="text"
                placeholder="Modalidade de atendimento do projeto"
                maxLength={3}
                isInvalid={!!errors.modalidade_atendimento}
                {...register('modalidade_atendimento')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.modalidade_atendimento?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <Form.Label>Em execução</Form.Label>
              <Form.Select
                isInvalid={!!errors.em_execucao}
                {...register('em_execucao')}
              >
                <option value={0} key={0}>
                  {'Selecione uma opção'}
                </option>
                <option value={'S'} key={1}>
                  {'SIM'}
                </option>
                <option value={'N'} key={2}>
                  {'NÃO'}
                </option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.em_execucao?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <Form.Label>Data inicial</Form.Label>
              <Form.Control
                type="date"
                placeholder="Data inicial do período"
                isInvalid={!!errors.dt_inicio}
                {...register('dt_inicio')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.dt_inicio?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <Form.Label>Data final prevista</Form.Label>
              <Form.Control
                type="date"
                placeholder="Data final prevista do projeto"
                isInvalid={!!errors.dt_fim_prev}
                {...register('dt_fim_prev')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.dt_fim_prev?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <Form.Label>Data final real (opcional)</Form.Label>
              <Form.Control
                type="date"
                placeholder="Data final real do projeto"
                isInvalid={!!errors.dt_fim_real}
                {...register('dt_fim_real')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.dt_fim_real?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default NovoInfoForm;

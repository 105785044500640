import React from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const NovoInfoForm = () => {
  const {
    register,
    formState: { errors }
  } = useFormContext();

  return (
    <Card className="mb-3">
      <Card.Header as="h5" className="bg-body-tertiary">
        Informações Gerais
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
        <Col md="12">
            <Form.Group>
              <Form.Label>Versão</Form.Label>
              <Form.Control
                type="text"
                placeholder="{Quantidade de anos completos do projeto}.{Ano}.{Mes}.{Dia}.{Quantidade de Alterações do dia} - Exemplo: 0.2024.01.01.1"
                isInvalid={!!errors.versao}
                {...register('versao')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.versao?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <Form.Label>Descrição</Form.Label>
              <Form.Control
                type="text"
                as='textarea'
                rows={2}
                placeholder="Descrição da alteração da versão"
                isInvalid={!!errors.descricao}
                {...register('descricao')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.descricao?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default NovoInfoForm;

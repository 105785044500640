import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import team3 from 'assets/img/team/avatar.png';
import Avatar from 'components/common/Avatar';
import { toast } from 'react-toastify';
import http from 'components/http';

const ProfileDropdown = () => {
  const navigate = useNavigate();
  const usuario = JSON.parse(localStorage.getItem('user'));

  const logout = () => {
    http
      .post('logout')
      .then(() => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');

        navigate('/auth/entrar');
        //window.location.reload();
      })
      .catch(error => {
        console.log(error);

        toast.error('Erro ao sair do sistema', {
          theme: 'colored',
          position: 'top-right'
        });
      });
  };

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        <Avatar src={team3} />
      </Dropdown.Toggle>     
      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item disabled>{usuario?.nome_reduzido}</Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item as={Link} to="/usuarios/perfil">
            Perfil            
          </Dropdown.Item>          
          <Dropdown.Item onClick={logout}>Sair</Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import MaskedInput from 'react-text-mask';
import http from 'components/http';

const LoginForm = ({ hasLabel, layout }) => {
  const navigate = useNavigate();

  // State
  const [formData, setFormData] = useState({
    cpf: '',
    senha: ''
  });

  // Handler
  const handleSubmit = e => {
    e.preventDefault();

    localStorage.removeItem('token');
    localStorage.removeItem('user');

    console.log(formData);

    http
      .request({
        url: 'login',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
          //'Access-Control-Allow-Origin': '*'
        },
        data: formData
      })
      .then(response => {
        if (response.data.token) {
          const token = response.data.token;
          const usuario = JSON.stringify(response.data.user);

          localStorage.setItem('token', token);
          localStorage.setItem('user', usuario);

          toast.success('Logado com sucesso', {
            theme: 'colored',
            position: 'top-right'
          });

          navigate('/dashboard');
          //window.location.reload();
        } else {
          toast.error('Erro ao realizar o login', {
            theme: 'colored',
            position: 'top-right'
          });
        }
      })
      .catch(error => {
        console.log(error);
        if (error.response.data?.error) {
          toast.error('CPF e/ou senha incorretos', {
            theme: 'colored',
            position: 'top-right'
          });
        } else {
          toast.error('Erro ao realizar o login', {
            theme: 'colored',
            position: 'top-right'
          });
        }
      });
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>CPF</Form.Label>}
        <MaskedInput
          // eslint-disable-next-line prettier/prettier          
          mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
          className="form-control"
          guide={false}
          placeholder={!hasLabel ? 'CPF' : ''}
          value={formData.cpf}
          name="cpf"
          onChange={handleFieldChange}  
          autoFocus={true}        
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>Senha</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Senha' : ''}
          value={formData.senha}
          name="senha"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe" className="mb-0">
            <Form.Check.Input
              type="checkbox"
              name="remember"
              checked={formData.remember}
              onChange={e =>
                setFormData({
                  ...formData,
                  remember: e.target.checked
                })
              }
            />
            <Form.Check.Label className="mb-0 text-700">
              Lembrar de mim
            </Form.Check.Label>
          </Form.Check>
        </Col>

        <Col xs="auto">
          <Link
            className="fs--1 mb-0"
            to={`/authentication/${layout}/forgot-password`}
          >
            Esqueceu a senha?
          </Link>
        </Col>
      </Row>

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!formData.cpf || !formData.senha}
        >
          Entrar
        </Button>
      </Form.Group>
    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;

/* eslint-disable react/prop-types */
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import { dataFormatada } from 'helpers/utils';
import IconButton from 'components/common/IconButton';

const PeriodosConsultores = ({ periodos, esconderTabela, mostrarTabela }) => {
  //const [periodos, setPeriodos] = useState([]);
  //const [cargo, setCargo] = useState('');
  const navigate = useNavigate();

  const columns = [
    {
      accessor: '#',
      Header: '#',
      Cell: rowData => {
        const { id } = rowData.row.original;
        const cont = rowData?.row.index;
        return (
          <a
            href={'#'}
            onClick={e => {
              e.preventDefault();
              navigate(`/consultores/historico/${id}`);
            }}
          >
            {cont + 1}
          </a>
        );
      }
    },
    {
      accessor: 'valor_hora',
      Header: 'Valor hora',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { id, valor_hora_cargo } = rowData.row.original;
        return (
          <a
            href={'#'}
            onClick={e => {
              e.preventDefault();
              navigate(`/consultores/historico/${id}`);
            }}
          >
            R${' '}
            {valor_hora_cargo != null
              ? valor_hora_cargo.replace('.', ',')
              : ''}
          </a>
        );
      }
    },
    {
      accessor: 'dt_inicio',
      Header: 'Data inicial',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { id, dt_inicio } = rowData.row.original;
        return (
          <a
            href={'#'}
            onClick={e => {
              e.preventDefault();
              navigate(`/consultores/historico/${id}`);
            }}
          >
            {dt_inicio != null ? dataFormatada(dt_inicio, false) : ''}
          </a>
        );
      }
    },
    {
      accessor: 'dt_fim',
      Header: 'Data final',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { id, dt_fim } = rowData.row.original;
        return (
          <a
            href={'#'}
            onClick={e => {
              e.preventDefault();
              navigate(`/consultores/historico/${id}`);
            }}
          >
            {dt_fim != null ? dataFormatada(dt_fim, false) : 'Em aberto'}
          </a>
        );
      }
    }
  ];
  if (mostrarTabela === true) {
    return (
      <AdvanceTableWrapper
        columns={columns}
        data={periodos}
        sortable
        pagination
        perPage={10}
      >
        <Card className="mb-3">
          <Card.Header>
            <Row className="flex-between-center">
              <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                  Histórico da Cargo - {periodos[0]?.cargo.descricao}
                </h5>
              </Col>
              <Col sm="auto" className="ms-auto text-end ps-0">
                <div
                  id="orders-actions"
                  className="d-flex align-items-center mb-1"
                >
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="chevron-up"
                    transform="shrink-3"
                    className="me-3"
                    style={{ width: 210 }}
                    onClick={e => {
                      e.preventDefault();
                      esconderTabela();
                    }}
                  >
                    <span className="d-none d-sm-inline-block ms-1">
                      Fechar tabela
                    </span>
                  </IconButton>
                  <AdvanceTableSearchBox table placeholder={'Procurar'} />
                </div>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTableFooter
              rowCount={periodos?.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
    );
  }
};

export default PeriodosConsultores;

import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import http from 'components/http';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Opcao = () => { 
  const navigate = useNavigate();
    
  return (
    <Card className="mb-3">
      <Card.Header className="mb-2">
        <Row className="flex-between-center">
          <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
              Selecione um meio para cadastro
            </h5>
          </Col>          
          <Col xs="auto">
            <Button
              variant="link"
              className="text-secondary fw-medium p-0 me-3"
              type="button"
              onClick={() => navigate(-1)}
            >
              Voltar
            </Button>                    
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="p-0">
        <Row>
          <Col xl={2} lg={4} md={6} sm={12} key={1} className="ps-4">
            <Card className="h-500 mb-3">
              <Card.Body>
                <div className="d-flex align-items-center justify-content-center">
                  <div className="mb-0">
                    <h6 className="mb-0">
                      <Link
                        to={'/usuarios/adicionar/formulario'}
                        className="text-inherit"
                      >
                        <span className="nav-link-icon">
                          <FontAwesomeIcon icon="align-justify" />
                        </span>                        
                        <span className="nav-link-text ps-1">Formulário</span>
                      </Link>
                    </h6>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col xl={2} lg={4} md={6} sm={12} key={2} className="ps-4">
            <Card className="h-500 mb-3">
              <Card.Body>
                <div className="d-flex align-items-center justify-content-center">
                  <div className="mb-0">
                    <h6 className="mb-0">
                      <Link
                        to={'/usuarios/adicionar/planilha'}
                        className="text-inherit"
                      >
                        <span className="nav-link-icon">
                          <FontAwesomeIcon icon="file-excel" />
                        </span>                        
                        <span className="nav-link-text ps-1">Planilha</span>
                      </Link>
                    </h6>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Opcao;

/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const NovoInfoForm = ({ consultores, usuarios }) => {
  const {
    register,
    setValue,
    formState: { errors }
  } = useFormContext();

  const [listaConsultores, setListaConsultores] = useState([]);
  const [listaUsuarios, setListaUsuarios] = useState([]);

  useEffect(() => {
    console.log(consultores);
    setListaConsultores(consultores);
    setListaUsuarios(usuarios);
    setValue('consultor', 0);
    setValue('pessoa_comissionada', 0);
  }, [consultores, usuarios]);

  return (
    <Card className="mb-1">
      <Card.Header as="h5" className="bg-body-tertiary">
        Informações Gerais
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="6">
            <Form.Group>
              <Form.Label>Porcentagem</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  type="number"
                  min={'0.00'}
                  max={'999.00'}
                  step={'0.01'}
                  placeholder="Porcentagem da comissão"
                  isInvalid={!!errors.porcentagem_comissao}
                  {...register('porcentagem_comissao')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.porcentagem_comissao?.message}
                </Form.Control.Feedback>
                <InputGroup.Text id="basic-addon1">%</InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <Form.Label>Pessoa comissionada</Form.Label>
              <Form.Select
                isInvalid={!!errors.pessoa_comissionada}
                {...register('pessoa_comissionada')}
              >
                {listaUsuarios != null && listaUsuarios.length > 0 ? (
                  <option value={0} key={0}>
                    {'Selecione uma pessoa'}
                  </option>
                ) : (
                  <option hidden value={-1} key={0}></option>
                )}

                {listaUsuarios != null && listaUsuarios.length > 0 ? (
                  listaUsuarios.map(item => (
                    <option value={item.id} key={item.id}>
                      {item.cpf} / {item.nome_reduzido}
                    </option>
                  ))
                ) : (
                  <option value={0} key={0} disabled>
                    {'Não existem pessoas cadastradas'}
                  </option>
                )}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.pessoa_comissionada?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <Form.Label>Consultor</Form.Label>
              <Form.Select
                isInvalid={!!errors.consultor}
                {...register('consultor')}
              >
                {listaConsultores != null && listaConsultores.length > 0 ? (
                  <option value={0} key={0}>
                    {'Selecione um consultor'}
                  </option>
                ) : (
                  <option hidden value={-1} key={0}></option>
                )}

                {listaConsultores != null && listaConsultores.length > 0 ? (
                  listaConsultores.map(item => (
                    <option value={item.consultor?.id} key={item.consultor?.id}>
                      {item.consultor?.usuario?.nome_completo} -{' '}
                      {item.consultor?.tarefa?.descricao}
                    </option>
                  ))
                ) : (
                  <option value={0} key={0} disabled>
                    {'Não existem consultores cadastrados nesse projeto'}
                  </option>
                )}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.consultor?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default NovoInfoForm;

import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import http from 'components/http';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const Anos = () => {
  const [anos, setAnos] = useState([]);
  const { usuarioId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    http
      .get('apontamentos/anos/usuarios/' + usuarioId)
      .then(resposta => {
        setAnos(resposta.data);
      })
      .catch(erro => {
        if (erro.request.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          navigate('/auth/entrar');
        } else if (erro.request.status === 403) {
          toast.error('Você não possui permissão para aquela ação', {
            theme: 'colored',
            position: 'top-right'
          });

          navigate('/');
          //window.location.reload();
        } else {
          console.log(erro);
          toast.error('Erro ao listar os apontamentos', {
            theme: 'colored',
            position: 'top-right'
          });
        }
      });
  }, []);

  const displayAnos = anos.map((item, index) => {
    if (index == 0) {
      return (
        <Col xl={2} lg={4} md={6} sm={12} key={index} className="ps-4">
          <Card className="h-500 mb-3">
            <Card.Body>
              <div className="d-flex align-items-center justify-content-center">
                <div className="mb-0">
                  <h6 className="mb-0">
                    <Link
                      to={'/apontamentos/usuarios/' + usuarioId + '/' + item}
                      className="text-inherit"
                    >
                      {item}
                    </Link>
                  </h6>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      );
    } else {
      return (
        <Col xl={2} lg={4} md={6} sm={12} key={index}>
          <Card className="h-500 mb-3">
            <Card.Body>
              <div className="d-flex align-items-center justify-content-center">
                <div className="mb-0">
                  <h6 className="mb-0">
                    <Link
                      to={'/apontamentos/usuarios/' + usuarioId + '/' + item}
                      className="text-inherit"
                    >
                      {item}
                    </Link>
                  </h6>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      );
    }
  });

  return (
    <Card className="mb-3">
      <Card.Header className="mb-2">
        <Row className="flex-between-center">
          <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
            <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
              Selecione um Ano
            </h5>
          </Col>
          <Col sm="auto" className="ms-auto text-end ps-0">
            <div
              id="orders-actions"
              className="d-flex align-items-center mb-1"
            ></div>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="p-0">
        <Row>{displayAnos}</Row>
      </Card.Body>
    </Card>
  );
};

export default Anos;

import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Card, Button } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import NovoInfoForm from './NovoInfoForm';
import { useNavigate, useParams } from 'react-router-dom';
import http from 'components/http';
import { toast } from 'react-toastify';

const schema = yup
  .object({
    tarefa: yup
      .number()
      .moreThan(0, 'Esse campo é obrigatório')
      .required('Esse campo é obrigatório'),    
  })
  .required();

const NovoConsultorTarefaForm = () => {
  const navigate = useNavigate();
  const { usuarioId } = useParams();
  const [tarefas, setTarefas] = useState([]);
  const [usuario, setUsuario] = useState('');

  useEffect(() => {
    http
      .get('tarefas')
      .then(resposta => {
        setTarefas(resposta.data);

        http
          .get('usuarios/' + usuarioId)
          .then(resposta => {
            setUsuario(resposta.data);
          })
          .catch(erro => {
            if (erro.request.status === 401) {
              localStorage.removeItem('token');
              localStorage.removeItem('user');

              navigate('/auth/entrar');
            } else if (erro.request.status === 403) {
              toast.error('Você não possui permissão para aquela ação', {
                theme: 'colored',
                position: 'top-right'
              });

              navigate('/');
              //window.location.reload();
            } else {
              console.log(erro);
              toast.error('Erro ao listar o usuário', {
                theme: 'colored',
                position: 'top-right'
              });
            }
          });
      })
      .catch(erro => {
        if (erro.request.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          navigate('/auth/entrar');
        } else if (erro.request.status === 403) {
          toast.error('Você não possui permissão para aquela ação', {
            theme: 'colored',
            position: 'top-right'
          });

          navigate('/');
          //window.location.reload();
        } else {
          console.log(erro);
          toast.error('Erro ao listar as tarefas', {
            theme: 'colored',
            position: 'top-right'
          });
        }
      });
  }, []);

  const submittedValues = {};

  const methods = useForm({
    resolver: yupResolver(schema)
  });

  const { handleSubmit, setError, setValue, reset } = methods;

  const onSubmit = data => {
    data['usuario'] = usuarioId;
    console.log(data);
    http
      .request({
        url: 'tarefas/usuarios/'+usuarioId,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      })
      .then(() => {
        const keys = Object.keys(data);
        for (const key of keys) {
          submittedValues[key] = '';
        }
        reset({ ...submittedValues });
        setValue('tarefa', 0);

        toast.success('Registro cadastrado com sucesso!', {
          theme: 'colored',
          position: 'top-right'
        });
      })
      .catch(erro => {
        console.log(erro);
        if (erro.request.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          navigate('/auth/entrar');
        } else if (erro.request.status === 403) {
          toast.error('Você não possui permissão para essa ação', {
            theme: 'colored',
            position: 'top-right'
          });
        } else {
          toast.error('Erro ao cadastrar o registro!', {
            theme: 'colored',
            position: 'top-right'
          });

          for (let key in erro.response.data) {
            setError(key, {
              type: 'custom',
              message: erro.response.data[key]
            });
          }
        }
      });
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="g-3">
          <Col xs={12}>
            <Card>
              <Card.Body>
                <Row className="flex-between-center">
                  <Col md>
                    <h5 className="mb-2 mb-md-0">
                      Nova tarefa do Consultor - {usuario?.nome_reduzido}
                    </h5>
                  </Col>
                  <Col xs="auto">
                    <Button
                      variant="link"
                      className="text-secondary fw-medium p-0 me-3"
                      type="button"
                      onClick={() => navigate(-1)}
                    >
                      Voltar
                    </Button>
                    <Button variant="primary" type="submit">
                      Adicionar
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={12}>
            <NovoInfoForm tarefas={tarefas} />
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};

export default NovoConsultorTarefaForm;

/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const InfoForm = ({ cargos, registro }) => {
  const {
    register,
    setValue,
    formState: { errors }
  } = useFormContext();

  useEffect(() => {
    setValue('usuario', registro?.usuario?.id);
    setValue('cargo', registro?.cargo?.id);
    setValue('valor_hora_cargo', registro?.valor_hora_cargo);
    setValue('dt_inicio', registro?.dt_inicio);
    setValue('dt_fim', registro?.dt_fim);
  }, [cargos, registro]);

  return (
    <Card className="mb-1">
      <Card.Header as="h5" className="bg-body-tertiary">
        Informações Gerais
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="12">
            <Form.Group>
              <Form.Label>Descrição da cargo</Form.Label>
              <Form.Select
                name="cargo"
                isInvalid={!!errors.cargo}
                onChange={e => {
                  setValue('cargo', e.target.value);
                }}
                {...register('cargo')}
              >
                {cargos != null && cargos.length > 0 ? (
                  <option value={0} key={0}>
                    {'Selecione uma cargo'}
                  </option>
                ) : (
                  <option hidden value={-1} key={0}></option>
                )}

                {cargos != null && cargos.length > 0 ? (
                  cargos.map(item => (
                    <option value={item.id} key={item.id}>
                      {item.descricao}
                    </option>
                  ))
                ) : (
                  <option value={0} key={0} disabled>
                    {'Não existem cargos cadastradas'}
                  </option>
                )}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.cargo?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <Form.Label>Valor hora</Form.Label>
              <Form.Control
                type="number"
                min={'0.00'}
                max={'999.00'}
                step={'0.01'}
                placeholder="Valor hora do período"
                isInvalid={!!errors.valor_hora_cargo}
                onChange={e => {
                  setValue('valor_hora_cargo', e.target.value);
                }}
                {...register('valor_hora_cargo')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.valor_hora_cargo?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <Form.Label>Data inicial</Form.Label>
              <Form.Control
                type="date"
                placeholder="Data inicial do período"
                isInvalid={!!errors.dt_inicio}
                onChange={e => {
                  setValue('dt_inicio', e.target.value);
                }}
                {...register('dt_inicio')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.dt_inicio?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <Form.Label>Data final (opcional)</Form.Label>
              <Form.Control
                type="date"
                placeholder="Data final do período"
                isInvalid={!!errors.dt_fim}
                onChange={e => {
                  setValue('dt_fim', e.target.value);
                }}
                {...register('dt_fim')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.dt_fim?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default InfoForm;

import React, { useEffect, useState } from 'react';
import { Col, Form, Row, Card, Button } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import NovoInfoForm from './NovoInfoForm';
import { useNavigate } from 'react-router-dom';
import http from 'components/http';
import { toast } from 'react-toastify';
import NovoContatoForm from './NovoContatoForm';
import NovoEnderecoForm from './NovoEnderecoForm';
import NovoSegurancaForm from './NovoSegurancaForm';

const schema = yup
  .object({
    tipo_usuario: yup
      .number()
      .moreThan(0, 'Esse campo é obrigatório')
      .required('Esse campo é obrigatório'),
    password: yup
      .string()
      .required('Esse campo é obrigatório')
      .min(8, 'Esse campo deve ter no mínimo 8 caracteres'),
    passwordConfirm: yup
      .string()
      .required('Esse campo é obrigatório')
      .min(8, 'Esse campo deve ter no mínimo 8 caracteres')
      .oneOf([yup.ref('password'), null], 'As senhas não são iguais'),
    cpf: yup
      .string()
      .required('Esse campo é obrigatório')
      .length(14, 'CPF inválido'),
    rg: yup
      .string()
      .required('Esse campo é obrigatório')
      .min(7, 'Esse campo deve ter no mínimo 13 caracteres'),
    nome_completo: yup
      .string()
      .required('Esse campo é obrigatório')
      .matches('^[A-zÀ-ú ]+$', 'Esse campo deve conter apenas letras'),
    nome_reduzido: yup
      .string()
      .required('Esse campo é obrigatório')
      .matches('^[A-zÀ-ú ]+$', 'Esse campo deve conter apenas letras'),
    cnpj: yup
      .string()
      .required('Esse campo é obrigatório')
      .length(18, 'CNPJ inválido'),
    razao_social: yup.string().required('Esse campo é obrigatório'),
    logradouro: yup.string().required('Esse campo é obrigatório'),
    complemento: yup.string(),
    cep: yup.string().min(10, 'Esse campo deve ter no mínimo 10 caracteres'),
    bairro: yup.string().required('Esse campo é obrigatório'),
    cidade: yup.string().required('Esse campo é obrigatório'),
    estado: yup
      .string()
      .required('Esse campo é obrigatório')
      .length(2, 'Estado não existe'),
    pais: yup.string().required('Esse campo é obrigatório'),
    email: yup
      .string()
      .email('Email inválido')
      .required('Esse campo é obrigatório'),
    celular: yup
      .string()
      .required('Esse campo é obrigatório')
      .min(8, 'Esse campo deve ter no mínimo 8 caracteres'),
    fone_1: yup.string(),
    fone_2: yup.string(),
    fone_3: yup.string(),
    data_nascimento: yup.string().required('Esse campo é obrigatório'),
    data_inicio_empresa: yup.string().required('Esse campo é obrigatório')
  })
  .required();

const NovoUsuarioForm = () => {
  const navigate = useNavigate();

  const [tiposUsuario, setTiposUsuario] = useState([]);
  const [cepVazio, setCepVazio] = useState(null);
  const [cpfVazio, setCpfVazio] = useState(null);
  const [cnpjVazio, setCnpjVazio] = useState(null);

  useEffect(() => {
    http
      .get('usuarios/tipos')
      .then(resposta => {
        setTiposUsuario(resposta.data);
      })
      .catch(erro => {
        if (erro.request.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          navigate('/auth/entrar');
        } else if (erro.request.status === 403) {
          toast.error('Você não possui permissão para aquela ação', {
            theme: 'colored',
            position: 'top-right'
          });

          navigate('/');
          //window.location.reload();
        } else {
          toast.error('Erro ao listar os tipos de usuário', {
            theme: 'colored',
            position: 'top-right'
          });
          console.log(erro);
        }
      });
  }, []);

  const submittedValues = {};

  const methods = useForm({
    resolver: yupResolver(schema)
  });

  const { handleSubmit, reset, setError, setValue } = methods;

  const onSubmit = data => {
    http
      .request({
        url: 'usuarios',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      })
      .then(() => {
        const keys = Object.keys(data);
        for (const key of keys) {
          submittedValues[key] = '';
        }
        reset({ ...submittedValues });

        setValue('tipo_usuario', 0);
        setCepVazio('');
        setCpfVazio('');
        setCnpjVazio('');

        toast.success('Pessoa cadastrada com sucesso!', {
          theme: 'colored',
          position: 'top-right'
        });
      })
      .catch(erro => {
        console.log(erro);
        if (erro.request.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          navigate('/auth/entrar');
        } else if (erro.request.status === 403) {
          toast.error('Você não possui permissão para essa ação', {
            theme: 'colored',
            position: 'top-right'
          });
        } else {
          toast.error('Erro ao cadastrar a pessoa!', {
            theme: 'colored',
            position: 'top-right'
          });

          for (let key in erro?.response?.data) {
            setError(key, {
              type: 'custom',
              message: erro?.response?.data[key]
            });
          }
        }
      });
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row className="g-3">
          <Col xs={12}>
            <Card>
              <Card.Body>
                <Row className="flex-between-center">
                  <Col md>
                    <h5 className="mb-2 mb-md-0">Nova Pessoa</h5>
                  </Col>
                  <Col xs="auto">
                    <Button
                      variant="link"
                      className="text-secondary fw-medium p-0 me-3"
                      type="button"
                      onClick={() => navigate(-1)}
                    >
                      Voltar
                    </Button>
                    <Button variant="primary" type="submit">
                      Adicionar
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={12}>
            <NovoInfoForm 
              tiposUsuario={tiposUsuario} 
              cpfVazio={cpfVazio} 
              cnpjVazio={cnpjVazio}
            />
          </Col>
          <Col lg={12}>
            <NovoContatoForm />
          </Col>
          <Col lg={12}>
            <NovoEnderecoForm cepVazio={cepVazio} />
          </Col>
          <Col lg={12}>
            <NovoSegurancaForm />
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};

export default NovoUsuarioForm;

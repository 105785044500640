/* eslint-disable react/prop-types */
import React, { Fragment, useEffect } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const ContatoForm = ({ cliente }) => {
  const {
    register,
    setValue,
    formState: { errors }
  } = useFormContext();

  useEffect(() => {
    setValue('nome_contato_1', cliente.nome_contato_1);
    setValue('email_contato_1', cliente.email_contato_1);
    setValue('celular_contato_1', cliente.celular_contato_1);
    setValue('fone_contato_1', cliente.fone_contato_1);
    setValue('setor_contato_1', cliente.setor_contato_1);
    setValue('nome_contato_2', cliente.nome_contato_2);
    setValue('email_contato_2', cliente.email_contato_2);
    setValue('celular_contato_2', cliente.celular_contato_2);
    setValue('fone_contato_2', cliente.fone_contato_2);
    setValue('setor_contato_2', cliente.setor_contato_2);
    setValue('nome_contato_3', cliente.nome_contato_3);
    setValue('email_contato_3', cliente.email_contato_3);
    setValue('celular_contato_3', cliente.celular_contato_3);
    setValue('fone_contato_3', cliente.fone_contato_3);
    setValue('setor_contato_3', cliente.setor_contato_3);
  }, [cliente]);

  return (
    <Fragment>
      <Card className="mb-3">
        <Card.Header as="h5" className="bg-body-tertiary">
          Contato 1
        </Card.Header>
        <Card.Body>
          <Row className="gx-2 gy-3">
            <Col md="7">
              <Form.Group>
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nome do contato"
                  isInvalid={!!errors.nome_contato_1}
                  onChange={e => setValue('nome_contato_1', e.target.value)}
                  {...register('nome_contato_1')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.nome_contato_1?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="5">
              <Form.Group>
                <Form.Label>Setor</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Setor do contato"
                  isInvalid={!!errors.setor_contato_1}
                  onChange={e => setValue('setor_contato_1', e.target.value)}
                  {...register('setor_contato_1')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.setor_contato_1?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email do contato"
                  isInvalid={!!errors.email_contato_1}
                  onChange={e => setValue('email_contato_1', e.target.value)}
                  {...register('email_contato_1')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email_contato_1?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group>
                <Form.Label>Celular</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Celular do contato"
                  isInvalid={!!errors.celular_contato_1}
                  onChange={e => setValue('celular_contato_1', e.target.value)}
                  {...register('celular_contato_1')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.celular_contato_1?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group>
                <Form.Label>Fone</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Fone do contato"
                  isInvalid={!!errors.fone_contato_1}
                  onChange={e => setValue('fone_contato_1', e.target.value)}
                  {...register('fone_contato_1')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.fone_contato_1?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mb-3">
        <Card.Header as="h5" className="bg-body-tertiary">
          Contato 2 (opcional)
        </Card.Header>
        <Card.Body>
          <Row className="gx-2 gy-3">
            <Col md="7">
              <Form.Group>
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nome do contato"
                  isInvalid={!!errors.nome_contato_2}
                  onChange={e => setValue('nome_contato_2', e.target.value)}
                  {...register('nome_contato_2')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.nome_contato_2?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="5">
              <Form.Group>
                <Form.Label>Setor</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Setor do contato"
                  isInvalid={!!errors.setor_contato_2}
                  onChange={e => setValue('setor_contato_2', e.target.value)}
                  {...register('setor_contato_2')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.setor_contato_2?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email do contato"
                  isInvalid={!!errors.email_contato_2}
                  onChange={e => setValue('email_contato_2', e.target.value)}
                  {...register('email_contato_2')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email_contato_2?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group>
                <Form.Label>Celular</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Celular do contato"
                  isInvalid={!!errors.celular_contato_2}
                  onChange={e => setValue('celular_contato_2', e.target.value)}
                  {...register('celular_contato_2')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.celular_contato_2?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group>
                <Form.Label>Fone</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Fone do contato"
                  isInvalid={!!errors.fone_contato_2}
                  onChange={e => setValue('fone_contato_2', e.target.value)}
                  {...register('fone_contato_2')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.fone_contato_2?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Card className="mb-1">
        <Card.Header as="h5" className="bg-body-tertiary">
          Contato 3 (opcional)
        </Card.Header>
        <Card.Body>
          <Row className="gx-2 gy-3">
            <Col md="7">
              <Form.Group>
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nome do contato"
                  isInvalid={!!errors.nome_contato_3}
                  onChange={e => setValue('nome_contato_3', e.target.value)}
                  {...register('nome_contato_3')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.nome_contato_3?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="5">
              <Form.Group>
                <Form.Label>Setor</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Setor do contato"
                  isInvalid={!!errors.setor_contato_3}
                  onChange={e => setValue('setor_contato_3', e.target.value)}
                  {...register('setor_contato_3')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.setor_contato_3?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Email do contato"
                  isInvalid={!!errors.email_contato_3}
                  onChange={e => setValue('email_contato_3', e.target.value)}
                  {...register('email_contato_3')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email_contato_3?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group>
                <Form.Label>Celular</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Celular do contato"
                  isInvalid={!!errors.celular_contato_3}
                  onChange={e => setValue('celular_contato_3', e.target.value)}
                  {...register('celular_contato_3')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.celular_contato_3?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="4">
              <Form.Group>
                <Form.Label>Fone</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Fone do contato"
                  isInvalid={!!errors.fone_contato_3}
                  onChange={e => setValue('fone_contato_3', e.target.value)}
                  {...register('fone_contato_3')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.fone_contato_3?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default ContatoForm;

import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import http from 'components/http';
import { toast } from 'react-toastify';
import { dataFormatada } from 'helpers/utils';
import PeriodosConsultores from './Periodos';
import { Fragment } from 'react';
import IconButton from 'components/common/IconButton';
import CargosConsultores from './Cargos';

const ConsultoresProjetos = () => {
  const [consultores, setConsultores] = useState([]);
  const [cargos, setCargos] = useState([]);
  const [periodos, setPeriodos] = useState([]);
  const [projeto, setProjeto] = useState('');
  const [usuarioSelecionado, setUsuarioSelecionado] = useState('');
  const [showTabelaCargos, setShowTabelaCargos] = useState(false);
  const [showTabelaPeriodos, setShowTabelaPeriodos] = useState(false);
  const navigate = useNavigate();
  const { projetoId } = useParams();

  useEffect(() => {
    http
      .get(`projetos/${projetoId}/usuarios`)
      .then(resposta => {
        setConsultores(resposta.data);
        setProjeto(resposta.data[0]?.projeto);
      })
      .catch(erro => {
        if (erro.request.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          navigate('/auth/entrar');
        } else if (erro.request.status === 403) {
          toast.error('Você não possui permissão para aquela ação', {
            theme: 'colored',
            position: 'top-right'
          });

          navigate('/');
          //window.location.reload();
        } else {
          console.log(erro);
          toast.error('Erro ao listar os consultores do projeto', {
            theme: 'colored',
            position: 'top-right'
          });
        }
      });
  }, []);

  const buscarConsultorCargos = (consultorId, projetoId) => {
    http
      .get(`projetos/${projetoId}/usuarios/${consultorId}/cargos`)
      .then(resposta => {
        setCargos(resposta.data);
      })
      .catch(erro => {
        if (erro.request.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          navigate('/auth/entrar');
        } else if (erro.request.status === 403) {
          toast.error('Você não possui permissão para aquela ação', {
            theme: 'colored',
            position: 'top-right'
          });

          navigate('/');
          //window.location.reload();
        } else {
          console.log(erro);
          toast.error('Erro ao listar as informações do consultor no projeto', {
            theme: 'colored',
            position: 'top-right'
          });
        }
      });
  };

  const columns = [
    {
      accessor: '#',
      Header: '#',
      Cell: rowData => {
        const { usuario } = rowData.row.original;
        const cont = rowData?.row.index;
        return (
          <a
            href={'#'}
            onClick={e => {
              e.preventDefault();
              setUsuarioSelecionado(usuario);
              buscarConsultorCargos(usuario?.id, projetoId);
              setShowTabelaCargos(true);
            }}
          >
            {cont + 1}
          </a>
        );
      }
    },
    {
      accessor: 'cpf',
      Header: 'CPF',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { usuario } = rowData.row.original;
        return (
          <a
            href={'#'}
            onClick={e => {
              e.preventDefault();
              setUsuarioSelecionado(usuario);
              buscarConsultorCargos(usuario?.id, projetoId);
              setShowTabelaCargos(true);
            }}
          >
            {usuario?.cpf}
          </a>
        );
      }
    },
    {
      accessor: 'nome_completo',
      Header: 'Nome completo',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { usuario } = rowData.row.original;
        return (
          <a
            href={'#'}
            onClick={e => {
              e.preventDefault();
              setUsuarioSelecionado(usuario);
              buscarConsultorCargos(usuario?.id, projetoId);
              setShowTabelaCargos(true);
            }}
          >
            {usuario?.nome_completo}
          </a>
        );
      }
    },
    {
      accessor: 'nome_reduzido',
      Header: 'Nome reduzido',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { usuario } = rowData.row.original;
        return (
          <a
            href={'#'}
            onClick={e => {
              e.preventDefault();
              setUsuarioSelecionado(usuario);
              buscarConsultorCargos(usuario?.id, projetoId);
              setShowTabelaCargos(true);
            }}
          >
            {usuario?.nome_reduzido}
          </a>
        );
      }
    }
    /* {
      accessor: 'dt_fim',
      Header: 'Data de término da usuario',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { id, dt_fim } = rowData.row.original;
        return (
          <a
            href={'#'}
            onClick={e => {
              e.preventDefault();
              navigate(`/consultores/consultores/${id}/usuarios/${projetoId}`);
            }}
          >
            {dt_fim != null ? dataFormatada(dt_fim, false) : ''}
          </a>
        );
      }
    },
    {
      accessor: 'valor_hora_cargo',
      Header: 'Valor hora',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { id, valor_hora_cargo } = rowData.row.original;
        return (
          <a
            href={'#'}
            onClick={e => {
              e.preventDefault();
              navigate(`/consultores/consultores/${id}/usuarios/${projetoId}`);
            }}
          >
            {'R$ ' + valor_hora_cargo?.replace('.', ',')}
          </a>
        );
      }
    } */
  ];
  //if (consultores.length > 0) {
  return (
    <Fragment>
      <AdvanceTableWrapper
        columns={columns}
        data={consultores}
        sortable
        pagination
        perPage={10}
      >
        <Card className="mb-3">
          <Card.Header>
            <Row className="flex-between-center">
              <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                  Consultores do Projeto - {projeto?.nome}
                </h5>
              </Col>
              <Col sm="auto" className="ms-auto text-end ps-0">
                <div
                  id="orders-actions"
                  className="d-flex align-items-center mb-1"
                >
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="plus"
                    transform="shrink-3"
                    className="me-3"
                    style={{ width: 110 }}
                    onClick={e => {
                      e.preventDefault();
                      navigate(`/projetos/${projetoId}/consultores/adicionar`);
                    }}
                  >
                    <span className="d-none d-sm-inline-block ms-1">Nova</span>
                  </IconButton>
                  <AdvanceTableSearchBox table placeholder={'Procurar'} />
                </div>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTableFooter
              rowCount={consultores?.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
      <CargosConsultores
        cargos={cargos}
        mostrarTabela={showTabelaCargos}
        esconderTabela={() => setShowTabelaCargos(false)}
        selecionaPeriodos={periodos => setPeriodos(periodos)}
        mostrarPeriodos={() => setShowTabelaPeriodos(true)}
        usuario={usuarioSelecionado}
        projetoId={projetoId}
      />
      <PeriodosConsultores
        periodos={periodos}
        mostrarTabela={showTabelaPeriodos}
        esconderTabela={() => setShowTabelaPeriodos(false)}
      />
    </Fragment>
  );
  //}
};

export default ConsultoresProjetos;

import React, { Fragment } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const NovoGereciamentoForm = () => {
  const {
    register,
    formState: { errors }
  } = useFormContext();

  return (
    <Fragment>
      <Card className="mb-3">
        <Card.Header as="h5" className="bg-body-tertiary">
          Gerenciamento
        </Card.Header>
        <Card.Body>
          <Row className="gx-2 gy-3">
            <Col md="6">
              <Form.Group>
                <Form.Label>Nome do responsável - Administração</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nome do responsável - Administração"
                  isInvalid={!!errors.nome_responsavel_adm}
                  {...register('nome_responsavel_adm')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.nome_responsavel_adm?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                <Form.Label>Nome do gerente do projeto - RCiT</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nome do gerente do projeto - RCiT"
                  isInvalid={!!errors.nome_gerente_projeto_rcit}
                  {...register('nome_gerente_projeto_rcit')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.nome_gerente_projeto_rcit?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                <Form.Label>Nome do gerente do projeto - Cliente</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nome do gerente do projeto - Cliente"
                  isInvalid={!!errors.nome_gerente_projeto_cliente}
                  {...register('nome_gerente_projeto_cliente')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.nome_gerente_projeto_cliente?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md="6">
              <Form.Group>
                <Form.Label>Nome do diretor do projeto - Cliente</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Nome do diretor do projeto - Cliente"
                  isInvalid={!!errors.nome_diretor_projeto_cliente}
                  {...register('nome_diretor_projeto_cliente')}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.nome_diretor_projeto_cliente?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Fragment>
  );
};

export default NovoGereciamentoForm;

import http from 'components/http';
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Form, Row, Card, Button } from 'react-bootstrap';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import InfoForm from './InfoForm';
import LogForm from './LogForm';
import ModalExcluirRegistro from './ModalExcluirRegistro';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const schema = yup
  .object({
    usuario: yup
      .number()
      .moreThan(0, 'Esse campo é obrigatório')
      .required('Esse campo é obrigatório'),
    cargo: yup
      .number()
      .moreThan(0, 'Esse campo é obrigatório')
      .required('Esse campo é obrigatório'),
    valor_hora_cargo: yup
      .number()
      .moreThan(0, 'Esse campo é obrigatório')
      .required('Esse campo é obrigatório'),
    dt_inicio: yup.string().required('Esse campo é obrigatório'),
    dt_fim: yup.string().nullable().optional()
  })
  .required();

const ConsultorCargoForm = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const [registro, setRegistro] = useState({
    id: '',
    usuario: '',
    cargo: '',
    valor_hora_cargo: '',
    dt_inicio: '',
    dt_fim: '',
    usuario_insert: '',
    usuario_update: '',
    data_hora_insert: '',
    data_hora_update: ''
  });

  const [cargos, setCargos] = useState([]);
  const [showModalExcluir, setShowModalExcluir] = useState(false);

  useEffect(() => {
    http
      .get(`cargos`)
      .then(resposta => {
        setCargos(resposta.data);
      })
      .catch(erro => {
        if (erro.request.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          navigate('/auth/entrar');
        } else if (erro.request.status === 403) {
          toast.error('Você não possui permissão para aquela ação', {
            theme: 'colored',
            position: 'top-right'
          });

          navigate('/');
          //window.location.reload();
        } else {
          console.log(erro);
          toast.error('Erro ao listar as cargos', {
            theme: 'colored',
            position: 'top-right'
          });
        }
      });

    http
      .get(`consultores/${id}`)
      .then(resposta => {
        setRegistro(resposta.data);
      })
      .catch(erro => {
        if (erro.request.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          navigate('/auth/entrar');
        } else if (erro.request.status === 403) {
          toast.error('Você não possui permissão para aquela ação', {
            theme: 'colored',
            position: 'top-right'
          });

          navigate('/');
          //window.location.reload();
        } else {
          console.log(erro);
          toast.error('Erro ao exibir o registro', {
            theme: 'colored',
            position: 'top-right'
          });
        }
      });
  }, []);

  const methods = useForm({
    resolver: yupResolver(schema)
  });

  const { handleSubmit, setError } = methods;

  const onSubmit = data => {
    http
      .request({
        url: 'consultores/' + registro.id,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      })
      .then(response => {
        setRegistro(response.data);

        toast.success('Registro editado com sucesso!', {
          theme: 'colored',
          position: 'top-right'
        });
      })
      .catch(erro => {
        console.log(erro);
        if (erro.request.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          navigate('/auth/entrar');
        } else if (erro.request.status === 403) {
          toast.error('Você não possui permissão para essa ação', {
            theme: 'colored',
            position: 'top-right'
          });
        } else {
          toast.error('Erro ao editar o registro!', {
            theme: 'colored',
            position: 'top-right'
          });

          for (let key in erro.response.data) {
            setError(key, {
              type: 'custom',
              message: erro.response.data[key]
            });
          }
        }
      });
  };

  return (
    <Fragment>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row className="g-3">
            <Col xs={12}>
              <Card>
                <Card.Body>
                  <Row className="flex-between-center">
                    <Col md>
                      <h5 className="mb-2 mb-md-0">
                        Registro - {registro?.cargo?.descricao} - R${' '}
                        {registro?.valor_hora_cargo}
                      </h5>
                    </Col>
                    <Col xs="auto">
                      <Button
                        size="sm"
                        variant="link"
                        type="button"
                        className="text-secondary me-2"
                        onClick={() => {
                          navigate(-1);
                        }}
                      >
                        Voltar
                      </Button>
                      <Button
                        size="sm"
                        variant="falcon-danger"
                        className="me-3"
                        onClick={() => setShowModalExcluir(true)}
                      >
                        Excluir registro
                      </Button>
                      <Button size="sm" variant="primary" type="submit">
                        Salvar alterações
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={8}>
              <InfoForm cargos={cargos} registro={registro} />
            </Col>
            <Col lg={4}>
              <div className="sticky-sidebar">
                <LogForm registro={registro} />
              </div>
            </Col>
          </Row>
        </Form>
      </FormProvider>
      <ModalExcluirRegistro
        show={showModalExcluir}
        onHide={() => setShowModalExcluir(false)}
        registro={registro}
      />
    </Fragment>
  );
};

export default ConsultorCargoForm;

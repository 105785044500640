import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import http from 'components/http';
import { toast } from 'react-toastify';
import { dataFormatada } from 'helpers/utils';
import PeriodosConsultores from './Periodos';
import { Fragment } from 'react';
import IconButton from 'components/common/IconButton';

const CargosConsultores = () => {
  const [cargos, setCargos] = useState([]);
  const [periodos, setPeriodos] = useState([]);
  const [usuario, setUsuario] = useState('');
  const [showTabela, setShowTabela] = useState(false);
  const navigate = useNavigate();
  const { usuarioId } = useParams();

  useEffect(() => {
    http
      .get(`consultores/cargos/usuarios/${usuarioId}`)
      .then(resposta => {
        setCargos(resposta.data);
        setUsuario(resposta.data[0]?.usuario);
      })
      .catch(erro => {
        if (erro.request.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          navigate('/auth/entrar');
        } else if (erro.request.status === 403) {
          toast.error('Você não possui permissão para aquela ação', {
            theme: 'colored',
            position: 'top-right'
          });

          navigate('/');
          //window.location.reload();
        } else {
          console.log(erro);
          toast.error('Erro ao listar as cargos do consultor', {
            theme: 'colored',
            position: 'top-right'
          });
        }
      });
  }, []);

  const buscarInfoPeriodos = (cargoId, usuarioId) => {
    http
      .get(`consultores/cargos/${cargoId}/usuarios/${usuarioId}`)
      .then(resposta => {
        setPeriodos(resposta.data);
      })
      .catch(erro => {
        if (erro.request.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          navigate('/auth/entrar');
        } else if (erro.request.status === 403) {
          toast.error('Você não possui permissão para aquela ação', {
            theme: 'colored',
            position: 'top-right'
          });

          navigate('/');
          //window.location.reload();
        } else {
          console.log(erro);
          toast.error('Erro ao listar as periodos do consultor', {
            theme: 'colored',
            position: 'top-right'
          });
        }
      });
  };

  const columns = [
    {
      accessor: '#',
      Header: '#',
      Cell: rowData => {
        const { cargo } = rowData.row.original;
        const cont = rowData?.row.index;
        return (
          <a
            href={'#'}
            onClick={e => {
              e.preventDefault();
              buscarInfoPeriodos(cargo?.id, usuarioId);
              setShowTabela(true);
            }}
          >
            {cont + 1}
          </a>
        );
      }
    },
    {
      accessor: 'cargo',
      Header: 'Cargo',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { cargo } = rowData.row.original;
        return (
          <a
            href={'#'}
            onClick={e => {
              e.preventDefault();
              buscarInfoPeriodos(cargo?.id, usuarioId);
              setShowTabela(true);
            }}
          >
            {cargo?.descricao}
          </a>
        );
      }
    },
    {
      accessor: 'data_hora_insert',
      Header: 'Data de cadastro',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { cargo } = rowData.row.original;
        return (
          <a
            href={'#'}
            onClick={e => {
              e.preventDefault();
              buscarInfoPeriodos(cargo?.id, usuarioId);
              setShowTabela(true);
            }}
          >
            {cargo?.data_hora_insert != null
              ? dataFormatada(cargo?.data_hora_insert, false)
              : ''}
          </a>
        );
      }
    }
    /* {
      accessor: 'dt_fim',
      Header: 'Data de término da cargo',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { id, dt_fim } = rowData.row.original;
        return (
          <a
            href={'#'}
            onClick={e => {
              e.preventDefault();
              navigate(`/cargos/cargos/${id}/usuarios/${usuarioId}`);
            }}
          >
            {dt_fim != null ? dataFormatada(dt_fim, false) : ''}
          </a>
        );
      }
    },
    {
      accessor: 'valor_hora_cargo',
      Header: 'Valor hora',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { id, valor_hora_cargo } = rowData.row.original;
        return (
          <a
            href={'#'}
            onClick={e => {
              e.preventDefault();
              navigate(`/cargos/cargos/${id}/usuarios/${usuarioId}`);
            }}
          >
            {'R$ ' + valor_hora_cargo?.replace('.', ',')}
          </a>
        );
      }
    } */
  ];
  //if (cargos.length > 0) {
  return (
    <Fragment>
      <AdvanceTableWrapper
        columns={columns}
        data={cargos}
        sortable
        pagination
        perPage={10}
      >
        <Card className="mb-3">
          <Card.Header>
            <Row className="flex-between-center">
              <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                  Cargos do Consultor - {usuario?.nome_reduzido}
                </h5>
              </Col>
              <Col sm="auto" className="ms-auto text-end ps-0">
                <div
                  id="orders-actions"
                  className="d-flex align-items-center mb-1"
                >
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="plus"
                    transform="shrink-3"
                    className="me-3"
                    style={{ width: 110 }}
                    onClick={e => {
                      e.preventDefault();
                      navigate(
                        `/consultores/cargos/usuarios/${usuarioId}/adicionar`
                      );
                    }}
                  >
                    <span className="d-none d-sm-inline-block ms-1">Nova</span>
                  </IconButton>
                  <AdvanceTableSearchBox table placeholder={'Procurar'} />
                </div>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTableFooter
              rowCount={cargos?.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
      <PeriodosConsultores
        periodos={periodos}
        mostrarTabela={showTabela}
        esconderTabela={() => setShowTabela(false)}
      />
    </Fragment>
  );
  //}
};

export default CargosConsultores;

/* eslint-disable react/prop-types */
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import { dataFormatada } from 'helpers/utils';
import IconButton from 'components/common/IconButton';
import http from 'components/http';

const CargosConsultores = ({
  cargos,
  esconderTabela,
  mostrarTabela,
  selecionaPeriodos,
  mostrarPeriodos,
  usuario,
  projetoId
}) => {
    
  const navigate = useNavigate();
  const buscarInfoPeriodos = cargoId => {
    http
      .get(`projetos/${projetoId}/usuarios/${usuario?.id}/cargos/${cargoId}`)
      .then(resposta => {
        selecionaPeriodos(resposta.data);
        console.log(resposta.data);
      })
      .catch(erro => {
        if (erro.request.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          navigate('/auth/entrar');
        } else if (erro.request.status === 403) {
          toast.error('Você não possui permissão para aquela ação', {
            theme: 'colored',
            position: 'top-right'
          });

          navigate('/');
          //window.location.reload();
        } else {
          console.log(erro);
          toast.error('Erro ao listar as informações do consultor no projeto', {
            theme: 'colored',
            position: 'top-right'
          });
        }
      });
  };

  const columns = [
    {
      accessor: '#',
      Header: '#',
      Cell: rowData => {
        const { cargo } = rowData.row.original;
        const cont = rowData?.row.index;
        return (
          <a
            href={'#'}
            onClick={e => {
              e.preventDefault();
              buscarInfoPeriodos(cargo?.id);
              mostrarPeriodos();
            }}
          >
            {cont + 1}
          </a>
        );
      }
    },
    {
      accessor: 'descricao',
      Header: 'Descrição',
      headerProps: { className: 'text-900' },
      Cell: rowData => {
        const { cargo } = rowData.row.original;
        return (
          <a
            href={'#'}
            onClick={e => {
              e.preventDefault();
              buscarInfoPeriodos(cargo?.id);
              mostrarPeriodos();
            }}
          >
            {cargo?.descricao}
          </a>
        );
      }
    }
  ];
  if (mostrarTabela === true) {
    return (
      <AdvanceTableWrapper
        columns={columns}
        data={cargos}
        sortable
        pagination
        perPage={10}
      >
        <Card className="mb-3">
          <Card.Header>
            <Row className="flex-between-center">
              <Col xs={4} sm="auto" className="d-flex align-items-center pe-0">
                <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                  Cargos do Consultor - {usuario?.nome_reduzido}
                </h5>
              </Col>
              <Col sm="auto" className="ms-auto text-end ps-0">
                <div
                  id="orders-actions"
                  className="d-flex align-items-center mb-1"
                >
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="chevron-up"
                    transform="shrink-3"
                    className="me-3"
                    style={{ width: 210 }}
                    onClick={e => {
                      e.preventDefault();
                      esconderTabela();
                    }}
                  >
                    <span className="d-none d-sm-inline-block ms-1">
                      Fechar tabela
                    </span>
                  </IconButton>
                  <AdvanceTableSearchBox table placeholder={'Procurar'} />
                </div>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className="p-0">
            <AdvanceTable
              table
              headerClassName="bg-200 text-nowrap align-middle"
              rowClassName="align-middle white-space-nowrap"
              tableProps={{
                size: 'sm',
                striped: true,
                className: 'fs--1 mb-0 overflow-hidden'
              }}
            />
          </Card.Body>
          <Card.Footer>
            <AdvanceTableFooter
              rowCount={cargos?.length}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </Card.Footer>
        </Card>
      </AdvanceTableWrapper>
    );
  }
};

export default CargosConsultores;

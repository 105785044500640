/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

const InfoForm = ({ tarefa }) => {
  const {
    register,
    setValue,
    formState: { errors }
  } = useFormContext();

  useEffect(() => {
    setValue('descricao', tarefa.descricao);
  }, [tarefa]);

  return (
    <Card className="mb-3">
      <Card.Header as="h5">Informações Gerais</Card.Header>
      <Card.Body className="bg-body-tertiary">
        <Row className="gx-2 gy-3">
          <Col md="12">
            <Form.Group controlId="descricao">
              <Form.Label>Descrição</Form.Label>
              <Form.Control
                type="text"
                name="descricao"
                placeholder="Descrição da tarefa"
                {...register('descricao')}
                onChange={e => {
                  setValue('descricao', e.target.value);
                }}
                isInvalid={!!errors.descricao}
              />
              <Form.Control.Feedback type="invalid">
                {errors.descricao?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default InfoForm;

/* eslint-disable react/prop-types */
import http from 'components/http';
import React, { useEffect, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const InfoForm = ({ tarefas, apontamento, cargos }) => {
  const {
    register,
    setValue,
    formState: { errors }
  } = useFormContext();

  const navigate = useNavigate();
  const [projetos, setProjetos] = useState([]);
  const [nomeConsultor, setNomeConsultor] = useState('');

  useEffect(() => {
    if (apontamento?.projeto != '' && apontamento?.cargo != '') {
      selecionaProjetos(apontamento?.cargo?.id);      

      setNomeConsultor(apontamento?.usuario?.nome_completo);
      setValue('usuario', apontamento?.usuario?.id);
      setValue('dia_atividade', apontamento?.data_atividade?.split('-')[2]);
      setValue('hora_inicio', apontamento?.hora_inicio);
      setValue('hora_fim', apontamento?.hora_fim);
      setValue('desc_atividade', apontamento?.desc_atividade);
      setValue('tarefa', apontamento?.tarefa?.id);
      setValue('cargo', apontamento?.cargo?.id); 
      setTimeout(() => {                        
        setValue('projeto', apontamento?.projeto?.id);
      }, 1000);             
    }
  }, [apontamento, cargos, tarefas]);

  const selecionaProjetos = idCargo => {    
    http
      .get(
        `projetos/execucao/usuarios/${apontamento?.usuario?.id}/cargos/${idCargo}`
      )
      .then(resposta => {        
        //console.log(resposta.data);
        setProjetos(resposta.data);
      })
      .catch(erro => {
        if (erro.request.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');

          navigate('/auth/entrar');
        } else if (erro.request.status === 403) {
          toast.error('Você não possui permissão para aquela ação', {
            theme: 'colored',
            position: 'top-right'
          });

          navigate('/');
          //window.location.reload();
        } else {
          console.log(erro);
          toast.error('Erro ao listar os projetos', {
            theme: 'colored',
            position: 'top-right'
          });
        }
      });
  };

  return (
    <Card className="mb-1">
      <Card.Header as="h5" className="bg-body-tertiary">
        Informações Gerais
      </Card.Header>
      <Card.Body>
        <Row className="gx-2 gy-3">
          <Col md="6">
            <Form.Group>
              <Form.Label>Consultor</Form.Label>
              <Form.Control
                disabled
                type="text"
                placeholder="Consultor"
                value={nomeConsultor}
                isInvalid={!!errors.usuario}
              />
              <Form.Control.Feedback type="invalid">
                {errors.usuario?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <Form.Label>Cargo</Form.Label>
              <Form.Select
                name="cargo"
                isInvalid={!!errors.cargo}
                {...register('cargo')}
                onChange={e => {
                  setValue('cargo', e.target.value);
                  selecionaProjetos(e.target.value);
                }}
              >
                {cargos != null && cargos.length > 0 ? (
                  <option value={0} key={0}>
                    {'Selecione um cargo'}
                  </option>
                ) : (
                  <option hidden value={-1} key={-1}></option>
                )}

                {cargos != null && cargos.length > 0 ? (
                  cargos.map(item => (
                    <option value={item.cargo?.id} key={item.cargo?.id}>
                      {item.cargo?.descricao}
                    </option>
                  ))
                ) : (
                  <option value={0} key={0} disabled>
                    {'Selecione um consultor para visualizar seus cargos'}
                  </option>
                )}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.tarefa?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>          
          <Col md="6">
            <Form.Group>
              <Form.Label>Projeto</Form.Label>
              <Form.Select
                name="projeto"
                isInvalid={!!errors.projeto}
                {...register('projeto')}
              >
                {projetos != null && projetos.length > 0 ? (
                  <option value={0} key={0}>
                    {'Selecione um projeto'}
                  </option>
                ) : (
                  <option hidden value={-1} key={-1}></option>
                )}

                {projetos != null && projetos.length > 0 ? (
                  projetos.map(item => (
                    <option value={item.id} key={item.id}>
                      {item.nome} - {item.cliente?.razao_social}
                    </option>
                  ))
                ) : (
                  <option value={0} key={0} disabled>
                    {
                      'Selecione um cargo para visualizar os projetos que a contém'
                    }
                  </option>
                )}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.projeto?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <Form.Label>Tarefa</Form.Label>
              <Form.Select
                name="tarefa"
                isInvalid={!!errors.tarefa}
                {...register('tarefa')}
                onChange={e => {
                  setValue('tarefa', e.target.value);                  
                }}
              >
                {tarefas != null && tarefas.length > 0 ? (
                  <option value={0} key={0}>
                    {'Selecione uma tarefa'}
                  </option>
                ) : (
                  <option hidden value={-1} key={-1}></option>
                )}

                {tarefas != null && tarefas.length > 0 ? (
                  tarefas.map(item => (
                    <option value={item.tarefa?.id} key={item.tarefa?.id}>
                      {item.tarefa?.descricao}
                    </option>
                  ))
                ) : (
                  <option value={0} key={0} disabled>
                    {'Selecione um consultor para visualizar suas tarefas'}
                  </option>
                )}
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.tarefa?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <Form.Label>Dia da atividade</Form.Label>
              <Form.Control
                type="number"
                min={'1'}
                max={'31'}
                step={'1'}
                placeholder="Dia da atividade do apontamento"
                isInvalid={!!errors.dia_atividade}
                {...register('dia_atividade')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.dia_atividade?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <Form.Label>Hora inicial da atividade</Form.Label>
              <Form.Control
                type="time"
                isInvalid={!!errors.hora_inicio}
                {...register('hora_inicio')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.hora_inicio?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="4">
            <Form.Group>
              <Form.Label>Hora final da atividade</Form.Label>
              <Form.Control
                type="time"
                isInvalid={!!errors.hora_fim}
                {...register('hora_fim')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.hora_fim?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <Form.Label>Descrição da atividade</Form.Label>
              <Form.Control
                as="textarea"
                type="text"
                rows={6}
                placeholder="Descrição da atividade do apontamento"
                isInvalid={!!errors.desc_atividade}
                {...register('desc_atividade')}
              />
              <Form.Control.Feedback type="invalid">
                {errors.desc_atividade?.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default InfoForm;
